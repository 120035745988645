import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: false,
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Input() titleLabel:string = 'others.confirm';
  @Input() modalLabel:string = 'Are you sure in executing this action?';
  @Input() confirmLabel:string = 'others.confirm';
  @Input() cancelLabel:string = 'others.cancel';
  @Input() remarksLabel:string = 'others.remarks';
  @Input() isRequireRemarks:boolean = false;

  @Output() onConfirmResult = new EventEmitter();

  remarks:string = '';

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    let confirmPayload:any;
    if(!this.isRequireRemarks) confirmPayload = true;
    else confirmPayload = {status: true, remarks: this.remarks}

    this.onConfirmResult.emit(confirmPayload);
    this.activeModal.close(confirmPayload);
  }

  onCancelClick() {
    this.onConfirmResult.emit(false);
    this.activeModal.close(false);
  }

}
