import { UserGuideModule } from './user-guide/user-guide.module';
import { ContactUsComponent } from './home/contact-us/contact-us.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OrganisationInviteComponent } from './home/policies/policy-dashboard/organisation-invite/organisation-invite.component';
import { PrivacyPolicyComponent } from './home/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './home/terms-conditions/terms-conditions.component';
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from './services/auth/auth.guard';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { EmployeePayrollDetailComponent } from './admin/payroll/employee-payroll/employee-payroll-detail/employee-payroll-detail.component';
import { SiteQrComponent } from './admin/clocking/manage-sites/all-sites/site-qr/site-qr.component';
import { PayrollExportPreviewComponent } from './admin/payroll/manage-payroll/payroll-export-preview/payroll-export-preview.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'user-guide',
    loadChildren: () => import('./user-guide/user-guide.module').then(m => m.UserGuideModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'export',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'employee-payroll/:userId/detail/:payrollId/:payslipId',
        component: EmployeePayrollDetailComponent,
      },
      {
        path: 'employee-payroll/detail/:payrollId/:exportType/:exportFormat',
        component: PayrollExportPreviewComponent,
      },
      {
        path: 'site-qr/:siteId',
        component: SiteQrComponent,
      },
    ]
  },
  {
    path: "**",
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
