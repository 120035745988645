<div id="admin">
  @if (!isFullscreen) {
    <div class="page">
      <div class="page-header px-0 pb-2 mb-3">
        <div class="page-title">
          <div class="header">
            {{pageTitle | translate}}
          </div>
          <div id="overview-content" class="position-relative">
            <div class="directory pt-3">
              <!-- directory component -->
            <nav style="--bs-breadcrumb-divider:
                url(&#34;data:image/svg+xml,%3Csvg
                xmlns='http://www.w3.org/2000/svg' width='8'
                height='8'%3E%3Cpath 
                d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                aria-label="breadcrumb">
                <ol class="breadcrumb m-0" >
                  <li class="breadcrumb-item">{{'others.home' | translate}}</li>
                  <li class="breadcrumb-item">{{'Project Management' | translate}}</li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <!-- {{pageTitle | translate}} -->
                    @if (links.length > 0) {
                      {{(links[activeId]?.title || '-') | translate}}
                    }
                    @if (links.length === 0) {
                      {{pageTitle | translate}}
                    }
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <!-- <div class="page-nav" *ngIf="showPageTab">
        <div class="p-3 mb-2">
          <ul [(activeId)]="activeTab" (click)="change()" class="nav nav-tabs" ngbNav>
            <li [ngbNavItem]="link.id" *ngFor="let link of activeTabOptions">
              <a ngbNavLink [routerLink]="'./' + pageFragment + '/' + link.fragment">
                {{ link.tabTitle }}
              </a>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
    @if (showPageTab) {
      <app-navigation-tabs
        [activeTab]="activeTab"
        [links]="activeTabOptions"
        (onMenuChange)="change($event)">
      </app-navigation-tabs>
    }
    <div class="border border-rounded mx-3 mb-3">
      <router-outlet></router-outlet>
    </div>
  </div>
}

@if (isFullscreen) {
  <router-outlet></router-outlet>
}

</div>