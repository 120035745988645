@if(!isHideSidebar){
<div id="sidebar"
    class="sidebar d-flex flex-column justify-content-between py-0"
    [ngClass]="{'sidebar-closed' : isMinimized}">
    <div class="flex-fill d-flex">
        <div class="d-flex flex-column align-items-center p-0" 
            [ngClass]="{'border border-end border-bottom-0' : !isMinimized}"
            style="width: 70px;">
            <img src="/assets/icons/dashboard/maximize-arrow.svg" 
                class="sidebar-maximize-icons mb-3 pt-2" 
                [ngClass]="{'invisible': !isMinimized}"
                (click)="toggleSidebar()">
            <img src="/assets/logos/logo_only_transparent.svg" 
                class="sidebar-onepass-logo cursor-pointer mb-3"
                (click)="onNavigateClick('dashboard')">
            <div class="sidebar-content">
                @for (menu of visibleSystemOption; track menu) {
                    @if(checkPermission(menu.resource)){
                        @if(menu.children.length === 0){
                            <div class="sidebar-menu-item my-2 p-1 ps-0"
                                [ngClass]="{'selected-menu': menu.routerLink === selectedMenu?.routerLink}"
                                (click)="onMenuClick(menu)">
                                <ng-container *ngTemplateOutlet="menuItem; context: { menu: menu }">
                                </ng-container>
                            </div>
                        }
                    }
                    
                    
                    @if(menu.children.length > 0){
                        <div ngbDropdown 
                            #submenuDropdown="ngbDropdown"
                            class="dropdown-md sidebar-menu-item" 
                            [ngClass]="{'selected-menu': menu.routerLink === selectedMenu?.routerLink}"
                            [placement]="['right']" 
                            style="z-index: 200; width: 80px;"
                            container="body">
                            <div ngbDropdownToggle 
                                class="sidebar-menu-item my-2 p-1 ps-0 border-0">
                                <ng-container *ngTemplateOutlet="menuItem; context: { menu: menu }">
                                </ng-container>
                            </div>

                            <div ngbDropdownMenu class="rounded shadow-lg py-0">
                                @for (item of menu.children; track item) {
                                    @if(checkPermission(item.resource)){
                                        <div (click)="onSubmenuClick($event, menu, item, submenuDropdown)"
                                            class="d-flex flex-row justify-content-between p-3 px-4 bg-submenu">
                                            <ng-container *ngTemplateOutlet="dropdownMenuItem; context: { menu: item }">
                                            </ng-container>
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
        <div class="flex-fill d-flex flex-column p-2"
            style="width: 130px;">
            <div class="row mb-5">
                <div class="col">
                    <img src="/assets/icons/dashboard/minimize-arrow.svg" 
                        [ngClass]="{'invisible': isMinimized}"
                        class="sidebar-minimize-icons float-end pt-2" 
                        (click)="toggleSidebar()">
                </div>
            </div>
            
            <div class="row pe-4 mb-4">
                <p class="fw-bold text-start fs-6">{{'masterpass.dashboard' | translate | uppercase}}</p>
            </div>

            <div class="row pe-4 my-2 mb-1">
                <p class="fw-bold text-start fs-6 mb-2">{{'recent' | translate | uppercase}}</p>

                @for(menu of recentMenu; track menu.routerLink){
                    <p class="fw-bolder text-start text-nowrap text-black-50 fs-7"
                        (click)="onMenuClick(menu)">
                        {{menu.linkText | translate | uppercase}}
                    </p>
                }

                @if(recentMenu.length === 0){
                    <p class="fw-bolder text-start text-nowrap text-black-50 fs-7">
                        {{'-' | translate | uppercase}}
                    </p>
                }
            </div>

            <hr class="mt-1">

            <div class="row pe-4 my-2 mb-1">
                <p class="fw-bold text-start fs-6 mb-2">{{'favorites' | translate | uppercase}}</p>

                @for(menu of favoritesMenu; track menu.routerLink){
                    <p class="fw-bolder text-start text-nowrap text-black-50 fs-7"
                        (click)="onMenuClick(menu)">
                        <i class="bi-star-fill text-warning me-2"></i>
                        {{menu.linkText | translate | uppercase}}
                    </p>
                }

                @if(favoritesMenu.length === 0){
                    <p class="fw-bolder text-start text-nowrap text-black-50 fs-7">
                        {{'-' | translate | uppercase}}
                    </p>
                }
            </div>

            <hr class="mt-1">

            <div class="row pe-4 my-2 mb-1">
                <p class="fw-bold text-start fs-6 mb-2">{{'projects' | translate | uppercase}}</p>

                @for(menu of projectsMenu; track menu){
                    <div ngbDropdown 
                        #projectDropdown="ngbDropdown"
                        class="dropdown-md sidebar-menu-item ps-1" 
                        [placement]="['right']" 
                        style="z-index: 200; width: 80px;"
                        container="body">
                        <p ngbDropdownToggle 
                            class="fw-bolder text-start text-nowrap text-black-50 fs-7">
                            <i class="bi-pin-fill text-primary me-0"></i>
                            {{menu.linkText | translate | uppercase}}
                        </p>

                        <div ngbDropdownMenu class="rounded shadow-lg py-0">
                            @for (item of projectsSubmenu; track item) {
                                <div (click)="onSubmenuClick($event, menu, item, projectDropdown)"
                                    class="d-flex flex-row justify-content-between p-3 px-4 bg-submenu">
                                    <ng-container *ngTemplateOutlet="dropdownMenuItem; context: { menu: item }">
                                    </ng-container>
                                </div>
                            }
                        </div>
                    </div>
                }

                @if(projectsMenu.length === 0){
                    <p class="fw-bolder text-start text-nowrap text-black-50 fs-7">
                        {{'-' | translate | uppercase}}
                    </p>
                }
            </div>
          
        </div>
    </div>

    @if(!isMinimized){
        <div>
            <!-- Organisation name -->
            <div class="w-100" style="overflow-x: hidden;">
                @if (!isMinimized) {
                    <h3 class="org-title text-dark text-start fw-bold fs-6 mb-1"
                        [ngClass]="{'scroll-text': currentOrganisation?.name.length > 18}">
                        {{currentOrganisation?.name}}
                        @if (currentOrganisation?.name.length > 18) {
                            {{currentOrganisation?.name}}
                        }
                    </h3>
                }
                @if (isMinimized) {
                    <h3 class="org-title text-dark text-start fw-bold fs-7 mb-1"
                        [ngClass]="{'scroll-text': true}">
                        {{currentOrganisation?.name}}
                        @if (currentOrganisation?.name.length > 12) {
                            {{currentOrganisation?.name}}
                        }
                    </h3>
                }
            </div>
            <span class="badge rounded-pill bg-warning fs-6 py-1 mb-2"
                [ngClass]="{'fs-6 px-3': !isMinimized, 'fs-8 px-1': isMinimized}">
                {{ currentOrganisation?.uen || '' }}
            </span>
        
            <!-- Footer: Language selector -->
            <div id="layout-footer" class="mx-3 mt-2 mb-4">
                <hr class="mx-1">
                <div ngbDropdown #languageDropdown="ngbDropdown" placement="top" class="px-2 dropdown-sm hover-visible">
                    <p class="text-start fs-7 text-primary mb-1" ngbDropdownToggle>
                        {{'navigation.language' | translate}}
                        <i class="bi bi-chevron-up"></i>
                    </p>
                    <div ngbDropdownMenu class="pt-0 pb-0 mb-0" style="border-radius: 0.8rem;">
                        @for (item of languagesList; track item) {
                        <div
                            (click)="onLanguageSelectChange(item.code); languageDropdown.close();"
                            class="d-flex flex-row justify-content-between p-3 px-4 me-3 bg-language-select"
                            style="width: 10rem">
                            <div>
                            <span class="fw-bold">{{item.nativeName}}</span>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <p class="fs-7 fw-bold text-primary text-start ps-2">
                {{selectedLanguageName || '-'}}
                </p>
            </div>
        </div>
    }
</div>
}

<ng-template #menuItem let-menu="menu">
    @if(!menu.img1.includes('png')){
        <i class={{menu.img1}}></i>
    }
    @if(menu.img1.includes('png') || menu.img1.includes('svg')) {
        <img class="sidebar-icons img-fluid" [src]="menu.img1">
    }
    <span class="text-primary fw-bold mt-2">
        {{menu.linkText | translate | uppercase}}
    </span>
</ng-template>


<ng-template #dropdownMenuItem let-menu="menu">
    <div class="d-flex align-items-center">
        @if(!menu.img1?.includes('png')){
            <i class={{menu.img1}}></i>
        }
        @if(menu.img1?.includes('png') || menu.img1?.includes('svg')) {
            <img class="sidebar-icons img-fluid" [src]="menu.img1">
        }
        <span class="ms-2 fw-bolder">{{menu.linkText | translate | uppercase}}</span>
    </div>
    <i [id]="'star-' + menu.linkText" class="ms-2" 
        [ngClass]="{'bi-star-fill text-warning': checkFavorite(menu), 'bi-star': !checkFavorite(menu)}"
        (click)="onSetFavoriteClick(menu);"></i>
</ng-template>