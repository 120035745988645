<div class="p-4">
  <section class="position-relative pt-16 pb-44 bg-white overflow-hidden">
    <div class="position-relative container" style="z-index: 1;">
      <div class="text-center p-4">
        <img class="img-fluid" 
            src="assets/illustrations/state-maintenance.png">
      </div>
      <div class="w-75 mx-auto">
        <p class="fs-5 fw-bold lh-sm mb-5 text-center">
          {{"navigation.under-construction" | translate}}
        </p>
        <div class="d-flex justify-content-center">
          <a [routerLink]="'/home/dashboard'" class="text-white">
            <button class="btn btn-primary text-white fs-6 fw-bold px-4 py-3 mx-2" 
              type="button">
              {{'navigation.back-to-homepage' | translate | uppercase}}
            </button>
          </a>
          <a [routerLink]="'/contact-us'">
            <button class="btn btn-outline-primary fs-6 fw-bold px-4 py-3 mx-2" 
              type="button">
              {{'navigation.contact-support' | translate | uppercase}}
            </button>
          </a>
        </div>
      </div>
    </div>
  </section>
</div>