<div class="container p-4 mt-4">
    <div id="overview-content" class="position-relative">
 
        <ng-container *ngIf="isLoading">
          <app-loading-indicator></app-loading-indicator>
        </ng-container>

        <ng-container *ngIf="!isLoading">

            <div class="row">
                <div class="col-2"></div>

                <div class="col-8">
                    <div class="row mb-4">
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-default" (click)="onBackClick()">
                                <i class="bi-arrow-left"></i> {{'others.back' | translate | titlecase}}
                            </button>
                            <button (click)="onExportClick()" class="btn btn-primary p-3">
                                <i class="bi-download me-2"></i>
                                {{'payroll.save-payslip-comp' | translate}}
                            </button>
                        </div>
                    </div>

                    <div class="border rounded p-2 pt-3">
                        <div id="export" [style]="textStyle">
                            <div class="d-flex flex-column justify-content-between" style="height: 297mm;">
                                <div>
                                    <div class="row mb-3 mx-1 p-3 bg-info2 rounded">
                                        <div class="d-flex flex-column">
                                            <h2 class="mb-0 fw-bold" [style]="textStyle">
                                                {{'payroll.payslip' | translate}}
                                            </h2>
                                            <p [style]="textStyle">
                                                {{'payroll.period' | translate}}: {{data.payroll.startDate * 1000 | date: 'dd MMMM yyyy'}}
                                                - {{data.payroll.endDate * 1000 | date: 'dd MMMM yyyy'}}
                                            </p>
                                        </div>
                                    </div>
                        
                                    <div class="row mb-4 mx-1 p-3 bg-info2 rounded">
                                        <div class="d-flex flex-row justify-content-between b-3 mb-3">
                                            <div class="d-flex flex-row mb-3">
                                                <div class="position-relative">
                                                    <img
                                                        [src]="staffData?.imgUrl"
                                                        [default]="'../../../../../assets/identicon.png'"
                                                        alt=""
                                                        style="object-fit: cover;"
                                                        class="profile-pic-xl position-relative me-4 border border-dark">
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <span class="mt-2 fs-6">
                                                        <b class="fs-5" [style]="textStyle">
                                                            {{staffData?.name || '-'}} 
                                                        </b>
                                                    </span>
                                                    <span class="mt-1 fs-7" [style]="textStyle">
                                                        {{staffData?.id || '-'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row justify-content-between">
                                            <div class="d-flex flex-column">
                                                <b class="fs-6" [style]="textStyle">
                                                    {{'profile.department' | translate}}
                                                </b>
                                                <span class="fs-7" [style]="textStyle">
                                                    {{staffData?.department.name || '-'}}
                                                </span>
                                           </div>
                                           <div class="d-flex flex-column">
                                                <b class="fs-6" [style]="textStyle">
                                                    {{'profile.role' | translate}}
                                                </b>
                                                <span class="fs-7" [style]="textStyle">
                                                    {{staffData?.role.roles || '-'}}
                                                </span>
                                           </div>
                                           <div class="d-flex flex-column">
                                                <b class="fs-6" [style]="textStyle">
                                                    {{'profile.mail' | translate}}
                                                </b>
                                                <span class="fs-7" [style]="textStyle">
                                                    {{staffData?.email || '-'}}
                                                </span>
                                           </div>
                                           <div class="d-flex flex-column">
                                                <b class="fs-6" [style]="textStyle">
                                                    {{'profile.contact-number' | translate}}
                                                </b>
                                                <span class="fs-7" [style]="textStyle">
                                                    {{staffData?.phoneNo || '-'}}
                                                </span>
                                           </div>
                                        </div>
                                    </div>
                        
                                    <div class="row mb-4 mx-1">
                                        <div class="col-6">
                                            <h4 class="fw-bold" [style]="textStyle">
                                                {{'payroll.earnings' | translate}}
                                            </h4>
                                            <table class="border border-dark w-100">
                                                <tr class="border border-dark">
                                                    <td class="d-flex justify-content-between p-3">
                                                        <span class="fs-6 fw-bold" [style]="textStyle">
                                                            {{'payroll.base-salary' | translate}}
                                                        </span>
                                                        <span class="fs-6 fw-bold" [style]="textStyle">
                                                            {{data.baseSalary | currency:'S$'}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr class="border border-dark">
                                                    <td class="d-flex justify-content-between p-3">
                                                        <span class="fs-6 fw-bold" [style]="textStyle">
                                                            {{'payroll.overtime-salary' | translate}}
                                                        </span>
                                                        <span class="fs-6 fw-bold" [style]="textStyle">
                                                            {{data.overtimeSalary | currency:'S$'}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <ng-container *ngFor="let item of payslipData;">
                                                    <tr *ngIf="item.payComponentConfig.type === 'EARNING'" 
                                                        class="border border-dark">
                                                        <td class="d-flex justify-content-between p-3">
                                                            <span class="fs-6 fw-bold" [style]="textStyle">
                                                                {{item.payComponentConfig.name}}
                                                            </span>
                                                            <span class="fs-6 fw-bold" [style]="textStyle">
                                                                {{item.amount | currency:'S$'}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <tr class="border border-dark">
                                                    <td class="d-flex justify-content-between p-3">
                                                        <br>
                                                    </td>
                                                </tr>
                                                <tr class="border border-dark">
                                                    <td class="d-flex justify-content-between p-3">
                                                        <span class="fs-6 fw-bold" [style]="textStyle">
                                                            {{'payroll.total-earnings' | translate}}
                                                        </span>
                                                        <span class="fs-6 fw-bold" [style]="textStyle">
                                                            {{(data?.totalEarnings | currency:'S$') || 0}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-6">
                                            <h4 class="fw-bold" [style]="textStyle">
                                                {{'payroll.deductions' | translate}}
                                            </h4>
                                            <table class="border border-dark w-100">
                                                <ng-container *ngFor="let item of payslipData;">
                                                    <tr *ngIf="item.payComponentConfig.type === 'DEDUCTION'" 
                                                        class="border border-dark">
                                                        <td class="d-flex justify-content-between p-3">
                                                            <span class="fs-6 fw-bold" [style]="textStyle">
                                                                {{item.payComponentConfig.name}}
                                                                <!-- <ng-container *ngIf="item.notes">
                                                                    <br>
                                                                    <span class="fs-7 fw-normal">({{item.notes}})</span>
                                                                </ng-container> -->
                                                            </span>
                                                            <span class="fs-6 fw-bold" [style]="textStyle">
                                                                {{item.amount | currency:'S$'}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <ng-container *ngIf="data.nDeduction === 0">
                                                    <tr class="border border-dark border-bottom-0">
                                                        <td class="d-flex justify-content-between p-3">
                                                            <span class="fs-6 fw-bold" [style]="textStyle">
                                                                {{'payroll.no-deduction' | translate}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                <tr *ngFor="let number of [].constructor(data.nEarning + 3 - (data.nDeduction === 0 ? 1 : data.nDeduction))" 
                                                    class="border border-dark border-bottom-0 border-top-0">
                                                    <td class="d-flex justify-content-between p-3">
                                                        <br>
                                                    </td>
                                                </tr>
                                                <tr class="border border-dark">
                                                    <td class="d-flex justify-content-between p-3">
                                                        <span class="fs-6 fw-bold" [style]="textStyle">
                                                            {{'payroll.total-deductions' | translate}}
                                                        </span>
                                                        <span class="fs-6 fw-bold" [style]="textStyle">
                                                            {{(data?.totalDeduction | currency:'S$') || 0}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                        
                                    <div class="row mb-4 me-2">
                                        <div class="d-flex flex-row-reverse">
                                            <span>
                                                <b class="fs-5" [style]="textStyle">
                                                    {{'payroll.net-salary' | translate}}: {{(data?.totalSalary | currency:'S$') || 0}}
                                                </b>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mx-2">
                                    <div class="col">
                                        <p class="fs-8" [style]="textStyle">
                                            {{'payroll.footer-text-1' | translate}}
                                        </p>
                                        <p class="fs-8" [style]="textStyle">
                                            {{'payroll.footer-text-2' | translate}}
                                        </p>
                                        <p class="fs-8" [style]="textStyle"> {{'payroll.footer-text-3' | translate}}</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>

        </ng-container>
 
    </div>
 </div>
 
 