export const projectNavigations: any = {
    'task-assignment': [
        { id: 0, title: 'All Tasks', tabTitle: 'All Tasks', fragment: 'all-tasks', role: 'admin' },
        { id: 1, title: 'My Tasks', tabTitle: 'My Tasks', fragment: 'my-tasks', role: 'all' },
        { id: 2, title: 'Settings', tabTitle: 'Settings', fragment: 'settings', role: 'admin' }
    ],
    'employee-tracking': [
        { id: 0, title: 'Employee Clocking', tabTitle: 'Employee Clocking', fragment: 'employee-clocking', role: 'all' },
        { id: 1, title: 'Employee List', tabTitle: 'Employee List', fragment: 'employee-list', role: 'admin' },
        // { id: 2, title: 'Settings', tabTitle: 'Settings', fragment: 'settings', role: 'admin' }
    ],
    'daily-task-report': [
        { id: 0, title: 'All Reports', tabTitle: 'All Reports', fragment: 'all-reports', role: 'admin' },
        { id: 1, title: 'My Reports', tabTitle: 'My Reports', fragment: 'my-reports', role: 'all' },
        { id: 2, title: 'Settings', tabTitle: 'Settings', fragment: 'settings', role: 'admin' }
    ]
}

