import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit, OnChanges {

  @ContentChild('tableActionButtonPassBy', { static: false }) tableActionButtonPassBy: TemplateRef<any> | undefined;

  @Output() paginationChange = new EventEmitter<any>(true);
  @Output() limitChange = new EventEmitter<any>(true);
  @Output() addButtonClick = new EventEmitter<any>(true);
  @Input() version = 1;
  @Input() style: string = 'table';
  @Input() rows = [];
  @Input() page = 1;
  @Input() limit = 10;
  @Input() hideLimit = false;
  @Input() meta: any;
  @Input() shortTable = false;
  @Input() addButton = false;
  @Input() buttonLabel: string = 'New';
  @Input() tokenPage: boolean = false;
  @Input() tokenLength: number = 1;

  rowCountFrom = 0;
  rowCountTo = 0;
  rowCountTotal = 0;

  constructor() { }

  ngOnInit(): void {
    this.countPageLimit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.rows) this.countPageLimit();
    if(changes.meta) this.countPageLimit();
  }

  countPageLimit(){
    // console.log('meta', this.meta)
    // console.log('rows', this.rows)

    this.rowCountFrom = (this.page-1) * this.limit + 1;
    this.rowCountTo = 0;
    // if current page limit is larger than rows length
    if(((this.page-1) * this.limit + this.limit) > this.rows.length) {
      if(this.meta) this.rowCountTo = Math.floor(((this.page-1) * this.limit) + this.rows.length);
      else this.rowCountTo = this.rows.length;
    } else {
      this.rowCountTo = (this.page-1) * this.limit + this.limit;
    }
    
    if(this.meta) this.rowCountTotal = this.meta.itemCount;
    else this.rowCountTotal = this.rows.length;

    if(this.tokenPage) {
      this.rowCountFrom = (this.tokenLength - 1) * this.limit + 1;
      this.rowCountTo = this.tokenLength * this.limit;
    }
  }

  onPageLimitChange(value: any) {
    this.limit = value.target.value;
    this.limitChange.emit({ limit: this.limit });
    this.countPageLimit();
  }

  onPrevPageClick() {
    if (this.tokenPage) this.paginationChange.emit({ page: this.page, type: 'prev' });
    else {
      if (this.page > 1) {
        this.page = this.page - 1;
        this.paginationChange.emit({ page: this.page, type: 'prev' });
      }
      this.countPageLimit();
    }
  }

  onNextPageClick() {
    if (this.tokenPage) this.paginationChange.emit({ page: this.page, type: 'next' });
    else {
      if (this.meta) {
        if (this.meta.hasNextPage) {
          this.page = this.page + 1;
          this.paginationChange.emit({ page: this.page, type: 'next' });
        }
      } else {
        if (this.page - 1 < Math.floor(this.rows.length / this.limit)) {
          this.page = this.page + 1;
          this.paginationChange.emit({ page: this.page, type: 'next' });
        }
      }
  
      this.countPageLimit();
    }
  }

}
