<div class="page-nav px-3">
    <ul [(activeId)]="activeTab" class="nav" ngbNav>
        <li *ngFor="let link of links"
            class="nav-item" 
            [ngbNavItem]="link.id" 
            (click)="change(link)">
            <a ngbNavLink class="nav-link fw-bold text-muted">
                {{ link.tabTitle | translate | uppercase}}
            </a>
        </li>
    </ul>
</div>