import { User } from '../masterpass/staff';

export interface Position {
    lat: number;
    lng: number;
}

export interface Label {
    text: string;
    color: string;
}

export interface Attendance {
    id: string;
    type: string | number;
    srcLat: string;
    srcLng: string;
    srcName: string;
    proximity: string;
    description: string;
    imgUrl: string;
    status: number;
    siteId: string;
    site?: SiteCompact;
    userId: string;
    user?: User;
    assisted: number;
    assistedBy: string | null;
    organisationId: string;
    dateCreated: string;
    dateUpdated: string;
}

export type AttendanceTable = ActivityLog & {
    open?: boolean;
}

export interface ActivityLog {
    userId: string;
    user?: string;
    name?: string;
    startTime: string;
    endTime: string | null;
    endTimeLong?: string | null;
    site?: Site;
    srcName: string;
    srcNameStart: string;
    srcNameEnd: string;
    descStart: string;
    descEnd: string;
    totalHours: string;
    imgUrlStart: string;
    imgUrlEnd: string | null;
    coordinateStart?: Position;
    coordinateEnd?: Position;
    siteCoordinate?: Position;
}

export interface SiteCompact {
    id: string;
    title: string;
    summary: string;
}

export interface Site {
    id: string;
    title: string;
    summary: string;
    position: Position;
    label: Label,
    geofencing: boolean;
    supervisorApproval: boolean;
    proximityLimit: number;
    overtimeBegin: string;
    organisationId: string;
    dateCreated: string;
    dateUpdated: string;
    projectId: string;
    timezone: string;
    establishedDate: string;
}

export interface SitePayload {
    title: string;
    summary: string;
    position: Position;
    label: Label,
    dateCreated: string;
    geofencing: boolean;
    supervisorApproval: boolean;
    proximityLimit: number;
    overtimeBegin: string;
    timezone: string;
    organisationId: string;
}


