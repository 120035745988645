// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import packageJson from '../../package.json';

export const environment = {
  production: false,
  appVersion: packageJson.version,
  apiUrl: 'https://api.dev.onepasserp.com',
  mapboxToken: "pk.eyJ1IjoieWFuZy1zdXBlcmluayIsImEiOiJjbHk1NXQxNmYwYXhhMmtzZjZncTYxNGhvIn0.T9hoydjtZlsCX0T1HZNwKw",
  googleMapsAPIToken: "AIzaSyCFO8h9EcdRUAW4Ukzx4Kd6K7mS7bGFKGQ",
  sentryDSN: "https://694cb54fa3e8bf6c6f6e647611fc2dfc@o1355373.ingest.us.sentry.io/4508798280794112"
};