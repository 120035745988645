import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit {

  isOpen: boolean = false;

  selectedChat: any;
  chatList: any = [];
  conversations: any = [];

  currentUserId: string = '';
  textbox: string = '';
  searchInput: string = '';

  unreadCount: number = 0;

  constructor() {
    this.currentUserId = localStorage.getItem('auth-user') ? JSON.parse(localStorage.getItem('auth-user')!).id : undefined;
  }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.chatList = [
      {
        id: '12312',
        name: 'Akira Sanada',
        imgUrl: 'https://api.onepasserp.com/avatar/default-avatar.png',
        previewMessage: 'Need confirmation about the previous assignment regarding the',
        timestamp: 1707279887,
        isRead: true
      },
      {
        id: '123124',
        name: 'Samantha Liew',
        imgUrl: 'https://api.onepasserp.com/avatar/default-avatar.png',
        previewMessage: 'The material you requested last time was in stock, currently we are unable to procure',
        timestamp: 1707272687,
        isRead: true
      },
      {
        id: '32131',
        name: 'Samantha Sanada',
        imgUrl: 'https://api.onepasserp.com/avatar/default-avatar.png',
        previewMessage: 'Currently working on procuring transport',
        timestamp: 1707272687,
        isRead: true
      },
      {
        id: '42123',
        name: 'Alisa Liew',
        imgUrl: 'https://api.onepasserp.com/avatar/default-avatar.png',
        previewMessage: 'Reporting latest addition to the crew, we have',
        timestamp: 1707011669,
        isRead: true
      },
    ]
    // this.setSelectedChat(this.chatList[0]);
  }

  fetchMessages() {
    this.conversations = [
      {
        user: {
          id: '2131',
          name: 'Akira Sanada',
          imgUrl: 'https://api.onepasserp.com/avatar/default-avatar.png',
        },
        createdAt: 1707011669,
        text: 'Need confirmation about the last notice you just submitted.'
      },
      {
        user: {
          id: this.currentUserId,
          name: '',
          imgUrl: '',
        },
        createdAt: 1707012873,
        text: 'Yes I checked it using the new growth meter device, the result is surprising'
      },
      {
        user: {
          id: '2131',
          name: 'Akira Sanada',
          imgUrl: 'https://api.onepasserp.com/avatar/default-avatar.png',
        },
        createdAt: 1707013762,
        text: 'Okay then, will have a team onsite soon enough'
      },
      {
        user: {
          id: this.currentUserId,
          name: '',
          imgUrl: '',
        },
        createdAt: 1707014858,
        text: 'Thank you, our onsite team is still on other taks right now'
      },
      {
        user: {
          id: '2131',
          name: 'Akira Sanada',
          imgUrl: 'https://api.onepasserp.com/avatar/default-avatar.png',
        },
        createdAt: 1707015879,
        text: 'Acknowledged'
      },
    ]

    this.conversations.sort((a: any, b: any) => b.createdAt - a.createdAt);

    setTimeout(() => {
      const element = document.getElementById("chatContent");
      if (element) element.scrollTop = element.scrollHeight;
    }, 10);
  }

  setSelectedChat(chat: any) {
    if (this.selectedChat === chat) this.selectedChat = null;
    else this.selectedChat = chat;

    if (this.selectedChat) {
      this.fetchMessages();
    }
  }

  setOpen(open: boolean) {
    this.isOpen = !open;
    if (!this.isOpen) this.selectedChat = null;
  }

  doSearch() {
    // console.log(this.searchInput);
  }

  onSendReplyClick() {
    const payload = {
      comment: this.textbox
    }

    this.conversations.push({
      userId: this.currentUserId,
      comment: this.textbox,
      createdAt: new Date(),
      updatedAt: new Date(),
      user: {
        id: this.currentUserId,
        name: JSON.parse(localStorage.getItem('auth-user')!).name,
        imgUrl: JSON.parse(localStorage.getItem('auth-user')!).imgUrl
      }
    })

    this.textbox = '';
  }

}
