import { PermissionService } from 'src/app/services/permission/permission.service';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserDetail } from 'src/app/interfaces/masterpass/staff';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private change: any = new BehaviorSubject(null)
  currentUser: UserDetail;

  constructor(
    private permissionService: PermissionService
  ) {
    this.currentUser = localStorage.getItem('auth-user') ? JSON.parse(localStorage.getItem('auth-user')!) : undefined;
  }

  /**
   * The function checks if a user has permission to access a specific resource.
   * @param {string | boolean} resource - The `resource` parameter can be either a string or a boolean.
   * @returns a boolean value. It returns true if the resource is either true or if it is found in the
   * user's permissions, and it returns false otherwise.
   */
  public checkPermission(resource: string | boolean) {
    // if owner return true
    if (this.currentUser?.owner === 1) return true;
    // override always true
    if (resource === true) return true;

    const all = this.permissionService.getUserPermission()
    if(all){
      const check = all.find((x: any) => x.resource === resource && x.active === true);
      if (check !== undefined) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  // https://www.freecodecamp.org/news/how-to-compare-arrays-in-javascript/
  /**
   * The function compares two arrays and returns true if they have the same length and all elements
   * are equal, otherwise it returns false.
   * @param {any} a - The parameter "a" is an array of any type.
   * @param {any} b - The parameter "b" is an array of any type.
   * @returns a boolean value. 
   */
  public compareArrays(a: any, b: any) {
    return a.length === b.length && a.every((element: any, index: number) => element === b[index]);
  }

  /**
   * The function converts a string to title case, where the first letter of each word is capitalized.
   * @param {string} string - The parameter `string` is a string that you want to convert to title
   * case.
   * @returns a string in title case.
   */
  public toTitleCase(string: string): string {
    string = ' ' + string.toLowerCase();
    let res = '';

    if (string.includes(' ')) {
      for (let i = 0; i < string.length - 1; i++) {
        if (string[i] === ' ') {
          res += string[i + 1].toUpperCase();
        } else {
          res += string[i + 1];
        }
      }
    } else {
      res = string[0].toUpperCase() + string.substring(1);
    }

    return res;
  }

  /**
   * The function calculates the number of workdays from the start of the week until the current day.
   * @returns the number of workdays from the start of the week until the current day.
   */
  public getNumWorkdaysFromStartOfWeek(): number {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 for Sunday, 1 for Monday, etc.
    const startOfWeek = new Date(now.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1))); // adjust when day is Sunday
    let numWorkdays = 0;

    for (let i = 0; i <= dayOfWeek; i++) {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);

      if (date.getDay() !== 0 && date.getDay() !== 6) {
        numWorkdays++;
      }
    }

    return numWorkdays;
  }

  /**
   * The function calculates the number of workdays that have passed since the start of the current
   * month.
   * @returns the number of workdays that have passed since the start of the current month.
   */
  public getNumWorkdaysFromStartOfMonth(): number {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    let numWorkdays = 0;

    for (let i = 0; i <= now.getDate() - 1; i++) {
      const date = new Date(startOfMonth);
      date.setDate(startOfMonth.getDate() + i);

      if (date.getDay() !== 0 && date.getDay() !== 6) {
        numWorkdays++;
      }
    }

    return numWorkdays;
  }

  /**
   * The function calculates the number of workdays from the start of the year until today.
   * @returns the number of workdays from the start of the year until the current date.
   */
  public getNumWorkdaysFromStartOfYear(): number {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    const daysFromStartOfYearToStartOfMonth = Math.floor((startOfMonth.getTime() - startOfYear.getTime()) / (1000 * 60 * 60 * 24));
    let numWorkdays = 0;

    // Add the number of weekdays from the start of the year until the start of the current month
    for (let i = 0; i <= daysFromStartOfYearToStartOfMonth; i++) {
      const date = new Date(startOfYear);
      date.setDate(startOfYear.getDate() + i);

      if (date.getDay() !== 0 && date.getDay() !== 6) {
        numWorkdays++;
      }
    }

    // Add the number of weekdays from the start of the current month until today
    for (let i = 0; i <= now.getDate() - 1; i++) {
      const date = new Date(startOfMonth);
      date.setDate(startOfMonth.getDate() + i);

      if (date.getDay() !== 0 && date.getDay() !== 6) {
        numWorkdays++;
      }
    }

    return numWorkdays;
  }

  /* The `getDateDuration` function calculates the number of days between two given dates inclusive the parameter date. 
  It takes two parameters, `date1` and `date2`, which are both of type `Date`. */
  public getDateDuration(date1: Date, date2: Date): number {
    // Get the difference in milliseconds between the two dates.
    const difference = date2.getTime() - date1.getTime();

    // Calculate the number of days in the difference.
    const days = Math.abs(difference / (1000 * 60 * 60 * 24));

    // Return the number of days.
    if (days < 1) return 1;
    return days + 1;
  }

  /**
   * The function calculates the number of days between two given dates.
   * @param {Date} date1 - The first date for which you want to calculate the day difference.
   * @param {Date} date2 - The second date for which you want to calculate the day difference.
   * @returns the number of days between the two input dates. If the difference is less than 1 day, it
   * will return 0.
   */
  public getDayDifference(date1: Date, date2: Date): number {
    // Get the difference in milliseconds between the two dates.
    const difference = date2.getTime() - date1.getTime();

    // Calculate the number of days in the difference.
    const days = Math.abs(difference / (1000 * 60 * 60 * 24));

    // Return the number of days.
    if (days < 1) return 0;
    return days;
  }

  public getWeeksDifference(date1: Date, date2: Date): number {
    // Get the difference in milliseconds between the two dates.
    const difference = date2.getTime() - date1.getTime();

    // Calculate the number of weeks in the difference.
    const weeks = Math.abs(difference / (1000 * 60 * 60 * 24 * 7));

    // Return the number of weeks.
    if (weeks < 1) return 0;
    return Math.ceil(weeks);
  }

  /**
   * The function calculates the difference in months between two dates, taking into account the day of
   * the month.
   * @param {Date} date1 - The first date for comparison.
   * @param {Date} date2 - The second date for which you want to calculate the month difference.
   * @returns the difference in months between two dates.
   */
  public getMonthDifference(date1: Date, date2: Date): number {
    // Get the difference in years and months between the dates
    const yearsDiff = date2.getFullYear() - date1.getFullYear();
    const monthsDiff = date2.getMonth() - date1.getMonth();

    // Calculate the total number of months
    const totalMonths = yearsDiff * 12 + monthsDiff;

    // Check if the day of the month in date2 is earlier than date1
    // If so, subtract 1 from the totalMonths
    if (date2.getDate() < date1.getDate()) {
      return totalMonths - 1;
    }

    return totalMonths;
  }

  public loopArrayAndExitWhenFound(array: any[], property: string): any {
    let i = 0, row: any, selected: any;

    function search(data: any, context: any): boolean {
      const date = context.datePipe.transform(data[property] * 1000, 'M/dd/yyyy');
      if (data[property] === new Date().toLocaleDateString()) {
        selected = data;
        return false;
      } else {
        return true;
      }
    }

    do {
      row = array[i];
      i++;
    } while (search(row, this) && i <= array.length - 1)

    return selected;
  }

  getChanges(): Observable<any> {
    return this.change.asObservable();
  }

  setChanges(source: any) {
    this.change.next(source);
  }
}
