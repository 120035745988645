import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum MasterpassServiceType {
  DASHBOARD_SETTINGS,
  LANGUAGE_LIST,
  LANGUAGE_SELF,
  NOTES
}

@Injectable({
  providedIn: 'root'
})
export class MasterpassService {

  private MP_URL: string;
  constructor(
    private httpSvc: HttpClient
  ) {
    this.MP_URL = environment.apiUrl;
  }

  getUrl(serviceType: MasterpassServiceType) {
    let url: string = '';
    switch (serviceType) {
      case MasterpassServiceType.DASHBOARD_SETTINGS:
        url = this.MP_URL + '/dashboard-settings';
        break;
      case MasterpassServiceType.LANGUAGE_LIST:
        url = this.MP_URL + '/languages/list';
        break;
      case MasterpassServiceType.LANGUAGE_SELF:
        url = this.MP_URL + '/languages/self';
        break;
      case MasterpassServiceType.NOTES:
        url = this.MP_URL + '/notes';
        break;
      default:
        break;
    }
    return url;
  }

  async create(serviceType: MasterpassServiceType, body: any): Promise<Observable<any>> {
    return this.httpSvc.post(this.getUrl(serviceType), body);
  }

  async delete(serviceType: MasterpassServiceType, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.delete(this.getUrl(serviceType) + '/' + path);
  }

  async detail(serviceType: MasterpassServiceType, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + '/' + path);
  }

  async list(serviceType: MasterpassServiceType, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + path);
  }

  async update(serviceType: MasterpassServiceType, body: any, path = ''): Promise<Observable<any>> {
    return this.httpSvc.put(this.getUrl(serviceType) + '/' + path, body);
  }

  async patch(serviceType: MasterpassServiceType, body: any, path = ''): Promise<Observable<any>> {
    return this.httpSvc.patch(this.getUrl(serviceType) + '/' + path, body);
  }
}
