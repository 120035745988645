import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NavbarEntries } from 'src/app/navigation-entries';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MasterpassService, MasterpassServiceType, SavedMenu } from 'src/app/services/masterpass/masterpass.service';
import { ProjectClientService } from 'src/app/services/project/project.client.service';
import { NavigationService } from 'src/app/services/shared/navigation.service';
import { ErrorHandlerService } from 'src/app/services/utilities/error-handler.service';
import { UtilityService } from 'src/app/services/utilities/utility.service';
import { environment } from 'src/environments/environment';
import { fullscreenRoutesProject } from '../../module-project/project.component';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

export type SidebarMenu = {
  img1?: string
  img2?: string
  resource: string | boolean
  routerLink: string
  linkText: string
  children?: SidebarMenu[]
}

const ProjectsRouting:SidebarMenu = {
  img1: "bi bi-kanban fs-4",
  img2: "bi bi-kanban fs-4",
  resource: true,
  routerLink: "project",
  linkText: "Project",
  children: [
      // {
      //     img1: "bi bi-chevron-left fs-4",
      //     img2: "bi bi-chevron-left fs-4",
      //     routerLink: "#back",
      //     linkText: "Project Management ",
      //     children: [],
      //     resource: true,
      //     style: 'heading'
      // },
      {
          img1: "bi bi-grid fs-4",
          img2: "bi bi-grid fs-4",
          routerLink: "/home/project/overview",
          linkText: "Overview",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-layout-text-window-reverse fs-4",
          img2: "bi bi-layout-text-window-reverse fs-4",
          routerLink: "/home/project/document-management",
          linkText: "Document Management",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-folder fs-4",
          img2: "bi bi-folder fs-4",
          routerLink: "/home/project/file-manager",
          linkText: "Master File Manager",
          resource: true,
          children: []
      },
  ]
}

const ProjectsSubmenu:SidebarMenu[] = [
  {
      img1: "bi bi-file-ruled fs-4",
      img2: "bi bi-file-ruled fs-4",
      routerLink: "/home/project/forms",
      linkText: "Forms",
      resource: true,
      children: []
  },
  {
      img1: "bi bi-columns-gap fs-4",
      img2: "bi bi-columns-gap fs-4",
      routerLink: "/home/project/project-overview",
      linkText: "Project Overview",
      resource: true,
      children: []
  },
  {
      img1: "bi bi-calendar2-range fs-4",
      img2: "bi bi-calendar2-range fs-4",
      routerLink: "/home/project/timeline",
      linkText: "Timeline",
      resource: true,
      children: []
  },
  {
      img1: "bi bi-list-task fs-4",
      img2: "bi bi-list-task fs-4",
      routerLink: "/home/project/task-assignment",
      linkText: "Task Assignment",
      resource: true,
      children: []
  },
  {
      img1: "bi bi-person-lines-fill fs-4",
      img2: "bi bi-person-lines-fill fs-4",
      routerLink: "/home/project/employee-tracking",
      linkText: "Employee Tracking",
      resource: true,
      children: []
  },
  {
      img1: "bi bi-file-earmark-check fs-4",
      img2: "bi bi-file-earmark-check fs-4",
      routerLink: "/home/project/daily-task-report",
      linkText: "Daily Task Report",
      resource: true,
      children: []
  },
  {
      img1: "bi bi-file-earmark-text fs-4",
      img2: "bi bi-file-earmark-text fs-4",
      routerLink: "/home/project/file-manager",
      linkText: "File Management",
      resource: true,
      children: []
  },
]

const ActivityLogsRoute:any = {
  img1: "bi-clock-history fs-5",
  img2: "bi-clock-history fs-5",
  routerLink: "/home/activity-logs",
  linkText: "clocking.activity-logs",
  resource: 'mp.activity-log.read',
  children: []
}

@Component({
  selector: 'app-sidebar',
  standalone: false,
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {

  visibleSystemOption: any[] = []; // All menu items
  visibleSystemOptionChildren: any = []; // Submenus for the corresponding parent item
  visibleParent:any = []; // To tell which parent menu should be opened
  systemOptions = NavbarEntries;
  devRoutes:any = ProjectsRouting;
  activityLogsRoute:any = ActivityLogsRoute;
  fullscreenRoutes: string[] = fullscreenRoutesProject;
  
  selectedMenu: SidebarMenu | undefined;
  recentMenu: SidebarMenu[] = [];
  favoritesMenu: SidebarMenu[] = [];
  projectsMenu: SidebarMenu[] = [];
  projectsSubmenu: SidebarMenu[] = ProjectsSubmenu;

  currentOrganisation: any;
  currentUser: any;
  selectedLanguage = "en";
  selectedLanguageName = "English";

  languageCodes: string[] = [];
  languagesList: any = [];

  isMinimized: boolean = false
  isHideSidebar: boolean = false;

  constructor(
    private authService: AuthService,
    private errorHandler: ErrorHandlerService,
    private masterpassService: MasterpassService,
    private navigationService: NavigationService,
    private router: Router,
    private translate: TranslateService,
    private toastrService: ToastrService,
    public userProService: ProjectClientService,
    public utilityService: UtilityService,
  ) {
    this.currentUser = localStorage.getItem('auth-user') ? JSON.parse(localStorage.getItem('auth-user')!) : {};
    this.selectedLanguage = this.currentUser?.language?.code || "en";
    this.selectedLanguageName = this.currentUser?.language?.nativeName || 'English';
    translate.use(this.selectedLanguage);
    translate.use(this.selectedLanguage);
  }

  ngOnInit(): void {
    this.fetchRecentMenu();
    this.fetchCurrentOrganisation();
    this.getLanguageList();

    this.visibleSystemOption = this.systemOptions;
    if(!environment.production) {
      this.visibleSystemOption = [...this.visibleSystemOption, this.devRoutes]
    }

    setTimeout(() => {
      if (window.innerWidth < 900 && !this.isMinimized) this.toggleSidebar();
      else if (window.innerWidth > 900 && this.isMinimized) this.toggleSidebar();
    }, 150);

    this.router.events.subscribe((event:any) => {
      if(event.type === 1){
        if(this.fullscreenRoutes.some(v => event.url.includes(v))){
          this.closeSidebar(); 
        } else if (!this.fullscreenRoutes.some(v => event.url.includes(v))) {
          setTimeout(() => { this.openSidebar() }, 200);
        }
      }
    });

    this.setActive();
    this.projectsMenu.push({
      resource: true,
      routerLink: '',
      linkText: 'Default Project',
      children: []
    })
  }

  async fetchRecentMenu(){
    this.recentMenu = [];
    const recents = this.masterpassService.getRecentMenu();
    if(recents){
      const findIndex = recents.findIndex((recent:SavedMenu) => recent.userId === this.currentUser.id);
      if(findIndex !== -1) this.recentMenu = recents[findIndex].menu;
    }

    this.favoritesMenu = [];
    const fav = this.masterpassService.getFavoriteMenu();
    if(fav){
      const findIndex = fav.findIndex((fav:SavedMenu) => fav.userId === this.currentUser.id);
      if(findIndex !== -1) this.favoritesMenu = fav[findIndex].menu;
    }

    // this.recentMenu = [
    //   {
    //     resource: '',
    //     routerLink: '/home/admin/payroll/my-payroll',
    //     linkText: 'HR / Payroll',
    //     children: []
    //   },
    //   {
    //     resource: '',
    //     routerLink: '/home/activity-logs',
    //     linkText: 'Activity Log',
    //     children: []
    //   },
    //   {
    //     resource: '',
    //     routerLink: '/home/project/document-management',
    //     linkText: 'Project / Document Management',
    //     children: []
    //   },
    //   {
    //     resource: '',
    //     routerLink: '/home/project/forms',
    //     linkText: 'Project / Form',
    //     children: []
    //   },
    //   {
    //     resource: '',
    //     routerLink: '/home/admin/clocking/overview',
    //     linkText: 'HR / Attendance',
    //     children: []
    //   },
    // ]
  }

  async fetchCurrentOrganisation(){
    await this.userProService.getCurrentUserOrganisation()?.subscribe({
      next: (item: any) => {
        this.currentOrganisation = item;
      },
      error: (error => {
        this.errorHandler.handleHttpError(error);
      })
    });
  }

  /**
   * The function `getLanguageList` asynchronously fetches a list of languages from a service and
   * populates language codes and a languages list accordingly.
   */
  async getLanguageList() {
    const req = await this.masterpassService.list(MasterpassServiceType.LANGUAGE_LIST);
    req.subscribe({
      next: (res) => {
        this.languageCodes = [];
        this.languagesList = res.data;

        this.languagesList.forEach((element: any) => {
          this.languageCodes.push(element.code);
        });
        this.translate.addLangs(this.languageCodes)
      },
      error: (e: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(e);
      }
    })
  }

  async onLanguageSelectChange(event: any) {
    this.selectedLanguage = event;
    const lang = this.languagesList.filter((language: any) => language.code === this.selectedLanguage)[0];
    const payload = { languageId: lang.id };

    const req = await this.masterpassService.patch(MasterpassServiceType.LANGUAGE_SELF, payload);
    req.subscribe({
      next: async (res) => {
        this.selectedLanguageName = lang.nativeName;
        this.toastrService.success("Language changed to " + lang.name);
        this.translate.use(this.selectedLanguage);

        let userData = JSON.parse(localStorage.getItem('auth-user') || '{}');
        userData.language = lang;
        await this.authService.saveUser(userData);
      },
      error: (e: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(e);
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let currentWidth = event.target.innerWidth;
    let sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>)
    let toggleMenu = document.getElementById("toggle-mobile-menu")
    let content = Array.from(document.getElementsByClassName('content') as HTMLCollectionOf<HTMLElement>)
    let mainContent = document.getElementById('homeContentWrapper')

    if (currentWidth >= 720) {
      if (toggleMenu) {
        toggleMenu.setAttribute("style", "display : none")
        if(sidebar[0]) sidebar[0].removeAttribute("style");
        if(content[0]) content[0].removeAttribute("style");
        if(mainContent) {
          if(!this.isHideSidebar) {
            mainContent.removeAttribute("style");
            if(this.isMinimized) mainContent.setAttribute("class", "router-content sidebar-closed");
            if(!this.isMinimized) mainContent.setAttribute("class", "router-content");
          }
        }
      }
    }

    if(!this.isHideSidebar){
      if (currentWidth < 900 && !this.isMinimized) this.toggleSidebar();
      else if (currentWidth > 900 && this.isMinimized) this.toggleSidebar();
    } 
  }

  toggleSidebar() {
    this.isMinimized = !this.isMinimized;

    let routerContent = Array.from(document.getElementsByClassName('router-content') as HTMLCollectionOf<HTMLElement>)
    switch(this.isMinimized){
      case true:
        routerContent[0].classList.add('sidebar-closed');
        break;
      case false:
        routerContent[0].classList.remove('sidebar-closed');
        break;
    }
  }

  openSidebar(){
    this.isHideSidebar = false;
    setTimeout(() => {
      let routerContent = Array.from(document.getElementsByClassName('router-content') as HTMLCollectionOf<HTMLElement>)
      routerContent[0]?.classList.remove('sidebar-closed');
    }, 100);
  }

  closeSidebar(){
    let routerContent = Array.from(document.getElementsByClassName('router-content') as HTMLCollectionOf<HTMLElement>)
    routerContent[0]?.classList.add('sidebar-hidden');
    setTimeout(() => {
      this.isHideSidebar = true;
    }, 100);
  }

  onMenuClick(data:SidebarMenu){
    if(data.children && data.children.length === 0) {
      this.router.navigateByUrl(data.routerLink);
      this.navigationService.setNavChangesA(data);

      const findIndex = this.recentMenu.findIndex((menu:SidebarMenu) => menu.routerLink === data.routerLink);
      if(findIndex === -1) {
        if(this.recentMenu.length >=5) this.recentMenu.pop();
        this.recentMenu.unshift({
          resource: data.resource,
          routerLink: data.routerLink,
          linkText: this.translate.instant(data.linkText),
          children: []
        })
      } else {
        this.recentMenu.splice(findIndex, 1);
        this.recentMenu.unshift({
          resource: data.resource,
          routerLink: data.routerLink,
          linkText: this.translate.instant(data.linkText),
          children: []
        })
      }

      this.saveRecentMenu();
    }

    this.selectedMenu = data;
  }

  onSubmenuClick(event: any, parentData:SidebarMenu, data:SidebarMenu, dropdownRef: NgbDropdown){
    this.router.navigateByUrl(data.routerLink);
    this.navigationService.setNavChangesA(data);

    this.selectedMenu = parentData;

    let parent = '';
    if(parentData.linkText === 'Default Project') parent = 'Project';
    else parent = this.translate.instant(parentData.linkText);

    const findIndex = this.recentMenu.findIndex((menu:SidebarMenu) => menu.routerLink === data.routerLink);
    if(findIndex === -1) {
      if(this.recentMenu.length >=5) this.recentMenu.pop();

      this.recentMenu.unshift({
        resource: data.resource,
        routerLink: data.routerLink,
        linkText: parent + ' / ' + this.translate.instant(data.linkText),
        children: []
      })
    } else {
      this.recentMenu.splice(findIndex, 1);
      this.recentMenu.unshift({
        resource: data.resource,
        routerLink: data.routerLink,
        linkText: parent + ' / ' + this.translate.instant(data.linkText),
        children: []
      })
    }
    this.saveRecentMenu();

    // console.log(event.target)
    if(!event.target.id.includes('star'))
      dropdownRef.close();
  }

  onSetFavoriteClick(data:SidebarMenu){
    const findIndex = this.favoritesMenu.findIndex((menu:SidebarMenu) => menu.routerLink === data.routerLink);
    if(findIndex === -1) {
      this.favoritesMenu.unshift({
        resource: data.resource,
        routerLink: data.routerLink,
        linkText: this.translate.instant(data.linkText),
        children: []
      })
    } else {
      this.favoritesMenu.splice(findIndex, 1);
    }

    this.saveFavoriteMenu()
  }

  /**
   * The function `saveRecentMenu` updates the recent menu for the current user in the masterpass
   * service.
   */
  saveRecentMenu(){
    const recents = this.masterpassService.getRecentMenu();
    if(recents){
      const findIndex = recents.findIndex((recent:SavedMenu) => recent.userId === this.currentUser.id);
      if(findIndex !== -1) {
        recents[findIndex].menu = this.recentMenu;
        this.masterpassService.setRecentMenu(recents);
      } 
      // when there are no value of current user recent menu
      else {
        const data = {
          userId: this.currentUser.id,
          menu: this.recentMenu
        }
        this.masterpassService.setRecentMenu([...recents, data]);
      }
    } 
    // when there are no value in recent menu storage
    else {
      const data = {
        userId: this.currentUser.id,
        menu: this.recentMenu
      }
      this.masterpassService.setRecentMenu([data]);
    }
  }

  /**
   * The `saveFavoriteMenu` function in TypeScript updates the favorite menu for the current user in a
   * service based on the favorite menu selection.
   */
  saveFavoriteMenu(){
    const fav = this.masterpassService.getFavoriteMenu();
    if(fav){
      const findIndex = fav.findIndex((recent:SavedMenu) => recent.userId === this.currentUser.id);
      if(findIndex !== -1) {
        fav[findIndex].menu = this.favoritesMenu;
        this.masterpassService.setFavoriteMenu(fav);
      } 
      // when there are no value of current user recent menu
      else {
        const data = {
          userId: this.currentUser.id,
          menu: this.favoritesMenu
        }
        this.masterpassService.setFavoriteMenu([...fav, data]);
      }
    } 
    // when there are no value in recent menu storage
    else {
      const data = {
        userId: this.currentUser.id,
        menu: this.favoritesMenu
      }
      this.masterpassService.setFavoriteMenu([data]);
    }
  }

  checkFavorite(menu:SidebarMenu){
    const findIndex = this.favoritesMenu.findIndex((fav:SidebarMenu) => fav.routerLink === menu.routerLink);
    if(findIndex !== -1) return true;
    else return false;
  }

  onNavigateClick(route: string, home:boolean = true) {
    if(home) this.router.navigate(['home', route]);
    else this.router.navigate([route]);
  }

  // used to set selected menu after page reload
  setActive() {
    const urlSections = this.router.url.split('/');
    if (this.router.url.includes('admin')) {
      const index = this.systemOptions.findIndex(x => x.linkText === 'navigation.human-resource');
      this.visibleParent.push('navigation.human-resource');
      this.visibleSystemOptionChildren.push(this.systemOptions[index]?.children);

      const findIndex = this.visibleSystemOptionChildren[0].findIndex((x:any) => x.routerLink.includes(urlSections[3]));
      if(findIndex !== -1) this.selectedMenu = this.visibleSystemOptionChildren[0][findIndex];
    } else {
      const findIndex = this.systemOptions.findIndex(x => x.routerLink.includes(urlSections[2]));
      if(findIndex !== -1) this.selectedMenu = this.systemOptions[findIndex];
    }
  }

  checkPermission(resource:string){
    return this.utilityService.checkPermission(resource);
  }
}
