import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/shared/navigation.service';
import { projectNavigations } from './project-navigations';
import { UtilityService } from 'src/app/services/utilities/utility.service';

export const fullscreenRoutesProject: string[] = [
  'templates/builder', 
  'workflows/builder', 
  'forms/create-form',
  'approvals/data-entry',
  'all-forms/preview'
]

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  allNavigationOptions = projectNavigations;

  links: any = [];
  title = 1;
  pageTitle: string = 'Projects Overview';
  pageFragment: string = '';

  activeId = 0;
  activeTab: number = 0;
  activeTabOptions: any = [];
  showPageTab: boolean = false;

  fullscreenRoutes: string[] = [...fullscreenRoutesProject, 'document-management', '/project/forms'];
  isFullscreen: boolean = false;

  constructor(
    private navigation: NavigationService,
    private router: Router,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.setTabMenu(this.router.url.split('/').slice(-2, -1)[0]?.replaceAll('-', ' ') || '')

    this.navigation.getNavChangesA().subscribe((data: any) => {
      if (data) {
        if(data.children.length === 0)
          this.setTabMenu(data.linkText);
      }
    })

    if(this.fullscreenRoutes.some(v => this.router?.url?.includes(v)))
      this.isFullscreen = true;
    else this.isFullscreen = false;

    // console.log(this.fullscreenRoutes)

    this.router.events.subscribe((event:any) => {
      if(event.type === 1){
        if(this.fullscreenRoutes.some(v => event?.url?.includes(v)))
          this.isFullscreen = true;
        else this.isFullscreen = false;
        
        // console.log(this.isFullscreen)
      }
    });
  }

  setTabMenu(title: string) {
    this.pageTitle = this.utilityService.toTitleCase(title);
    this.pageFragment = title.toLowerCase().replaceAll(' ', '-');
    this.links = this.allNavigationOptions[this.pageFragment];
    if(!this.links) this.links = [];

    this.activeTabOptions = this.allNavigationOptions[this.pageFragment]
    if (this.activeTabOptions) this.showPageTab = true;
    else this.showPageTab = false;
  }

  change(data?:any) {
    this.router.navigateByUrl('/home/project/'+ this.pageFragment + '/' + data.fragment);

    const index = this.links.findIndex((link: any) => link.fragment === data.fragment)
    if (index !== -1) {
      this.activeTab = this.links[index].id
      this.activeId = index;
      this.title = this.links[index].title
      this.pageTitle = this.links[index].title;
    }

    // console.log(this.activeId)
    // console.log(this.links[this.activeId].title)
    // console.log(this.links)
  }

  getCurrentUrl():string {
    return this.router.url;
  }

}
