import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LngLatLike, Map } from 'mapbox-gl';
import { Position, Site } from 'src/app/interfaces/human-resource/clocking';

@Component({
  selector: 'app-location-viewer-modal',
  templateUrl: './location-viewer-modal.component.html',
  styleUrls: ['./location-viewer-modal.component.css']
})
export class LocationViewerModalComponent implements OnInit {

  title: string = 'Location';
  type: string = '';
  // site!: Position;
  position!: Position;
  time!: number;
  siteName!: string;

  map!: Map;
  mapCenter:LngLatLike = {lng: 103.761592, lat: 1.356954};
  markers:any = [];
  public zoom: number = 15;

  public cancelBtnLabel: string = 'Close';

  constructor(
    public activeModal: NgbActiveModal,
  ) { }


  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.onCancel();
  }

  public onCancel(): void {
    // NOTE: closing modal shows console error, currently no idea how to fix it
    this.activeModal.close();
  }

  onMapReady(map: any) {
    this.map = map;

    // Clocking coordinate
    this.markers = [];
      this.markers.push({
        lat: this.position.lat || 0,
        lng: this.position.lng || 0,
        img: '../../../../../assets/icons/marker-icon.png'
      })
    this.map.panTo([this.position.lng, this.position.lat])
  }

}
