<div class="bg-white">
    <div class="modal-header bg-info2 p-4 pb-2 text-primary">
        <h1 class="modal-title fw-bold">{{'formbuilder.draw-sign' | translate}}</h1>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-3 px-4">
        <div class="row">
            <div class="form-group row p-2">
                <label class="fw-bold col-form-label text-primary">
                   {{"formbuilder.upload-sign-manually" | translate}} <span class="text-danger">*</span>
                   <small class="text-muted">({{"clocking.choose-one" | translate}})</small>
                </label>
                <input 
                   #fileSelect
                   type="file" 
                   class="custom-file-upload" 
                   accept="image/png, image/jpeg"
                   (change)="onFileSelect($event)"/>
                
                <div class="col d-flex align-items-center justify-content-between">
                   <div *ngIf="!isSelectedFile"
                      (click)="fileSelect.click();" 
                      class="w-100 bg-primary2 cursor-pointer border-2 border-dashed border-grey rounded d-flex flex-column justify-content-center align-items-center" style="height:200px">
                      <i class="bi-file-earmark fs-4 text-primary mb-3 me-2"></i> 
                      {{"clocking.select-file" | translate}} ({{'others.max' | translate}} 2MB)
                   </div>

                   <div *ngIf="isSelectedFile"
                      (click)="fileSelect.click()"
                      class="w-100 cursor-pointer border rounded d-flex flex-column justify-content-center align-items-center"
                      style="height: 200px; object-fit: contain; object-position: center;">
                      <img class="img img-fluid" style="height: 200px; object-fit: contain" [src]="preview">
                   </div>

                   <div *ngIf="!isDrawSignature"
                      (click)="onDrawSignBtnClick()" 
                      class="ms-2 w-100 bg-primary2 cursor-pointer border-2 border-dashed border-grey rounded d-flex flex-column justify-content-center align-items-center" style="height:200px">
                      <i class="bi-pencil fs-4 text-primary mb-3 me-2"></i> 
                      {{"formbuilder.draw" | translate}}
                   </div>

                   <div *ngIf="isDrawSignature"
                      (click)="onDrawSignBtnClick()"
                      class="w-100 ms-2 cursor-pointer border rounded d-flex flex-column justify-content-center align-items-center"
                      style="height: 200px; object-fit: contain; object-position: center;">
                      <img class="img img-fluid" [src]="preview">
                   </div>
                </div>
             </div>
        </div>
    </div>
    <div class="modal-footer bg-light p-3">
        <button type="button" 
            style="width: 120px;" 
            class="btn btn-outline-secondary me-2 p-3 fw-bold"
            (click)="onCancelClick()">
            {{'others.cancel' | translate}}
        </button>
        <button type="button" 
            style="width: 120px;" 
            class="btn btn-primary p-3 fw-bold"
            [disabled]="!isSelectedFile && !isDrawSignature"
            (click)="onConfirmClick()">
            {{'others.confirm' | translate}}
        </button>
    </div>
</div>