import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let header = new HttpHeaders();
    header = header.set('Accept-Language', 'en');
    header.append('Access-Control-Allow-Origin', '*');

    const token = localStorage.getItem('auth-token') as string;
    if (token != null && token != undefined && token != "undefined") {
      // header = header.set('Content-type', 'application/json');
      header = header.append('Authorization', 'Bearer ' + JSON.parse(token));
    }

    const collab = localStorage.getItem('collab-org') as string;
    if (collab != null && collab != undefined && collab != "undefined") {
      header = header.append('X-Collaboration-Organisation', collab)
    }

    const userLanguage = localStorage.getItem('auth-user');
    if (userLanguage != null && userLanguage != undefined && userLanguage != "undefined") {
      header = header.append('Accept-Language', JSON.parse(userLanguage).language.code || 'en');
    }

    // var organisationId;
    // if (localStorage.getItem('current-organisation') != null && localStorage.getItem('current-organisation') != undefined && localStorage.getItem('current-organisation') != "undefined") {
    //   organisationId = JSON.parse(localStorage.getItem('current-organisation') as string).id;
    // }

    request = request.clone({
      headers: header,
      responseType: 'json',
      withCredentials: true,
      // setParams: {
      //   organisationId: organisationId
      // }
    });

    // If environment api url contains localhost
    if (environment.apiUrl.includes('localhost')) {
      console.log("AuthInterceptor: ", request);
    }

    return next.handle(request);
  };
}
