export const NavbarEntries = [
    {
        img1: "bi bi-grid fs-5",
        img2: "bi bi-grid fs-5",
        resource: true,
        routerLink: "/home/dashboard",
        linkText: "navigation.dashboard",
        children: []
    },
    {
        img1: "/assets/icons/sidebar/policies.svg",
        img2: "/assets/icons/sidebar/policies.svg",
        routerLink: "/home/policies",
        resource: 'mp.permission.assign',
        linkText: "navigation.policies",
        children: []
    },
    {
        img1: "/assets/icons/sidebar/staff.svg",
        img2: "/assets/icons/sidebar/staff.svg",
        routerLink: "/home/staffs",
        linkText: "navigation.staffs",
        resource: 'mp.user.read',
        children: []
    },
    {
        img1: "bi bi-person-badge fs-4",
        img2: "bi bi-person-badge fs-4",
        resource: true,
        routerLink: "",
        linkText: "navigation.human-resource",
        children: [
            {
                img1: "/assets/icons/sidebar/payroll.svg",
                img2: "/assets/icons/sidebar/payroll.svg",
                routerLink: "/home/admin/payroll/my-payroll",
                linkText: "payroll.payroll",
                resource: "hr.payroll.read",
                children: []
            },
            {
                img1: "/assets/icons/sidebar/claims.svg",
                img2: "/assets/icons/sidebar/claims.svg",
                routerLink: "/home/admin/claims/overview",
                linkText: "claims.claims",
                resource: "hr.claims.basic",
                children: []
            },
            {
                img1: "/assets/icons/sidebar/clocking.svg",
                img2: "/assets/icons/sidebar/clocking.svg",
                routerLink: "/home/admin/clocking/overview",
                linkText: "clocking.clocking",
                resource: "hr.attendance.basic",
                children: []
            },
            {
                img1: "/assets/icons/sidebar/leave.svg",
                img2: "/assets/icons/sidebar/leave.svg",
                routerLink: "/home/admin/leave/overview",
                linkText: "leaves.leaves",
                resource: "hr.leave.basic",
                children: []
            },
            {
                img1: "/assets/icons/sidebar/daily-task.svg",
                img2: "/assets/icons/sidebar/daily-task.svg",
                routerLink: "/home/admin/daily-task/overview",
                linkText: "schedule.daily-task",
                resource: "hr.task-schedule.basic",
                children: []
            },
            {
                img1: "/assets/icons/sidebar/learning.svg",
                img2: "/assets/icons/sidebar/learning.svg",
                routerLink: "/home/admin/learning/overview",
                linkText: "navigation.learning",
                resource: true,
                children: []
            },
            // {
            //   img1: "/assets/icons/dark-dashboard/dark-people.png",
            //   img2: "/assets/icons/white-dashboard/white-people.png",
            //   routerLink: "/home/admin/management/overview",
            //   linkText: "Management",
            //   children: []
            // },
            // {
            //   img1: "/assets/icons/dark-dashboard/dark-accounts.png",
            //   img2: "/assets/icons/white-dashboard/white-accounts.png",
            //   routerLink: "/home/admin/accounts/overview",
            //   linkText: "Accounts",
            //   children: []
            // },
        ]
    },
    // {
    //     img1: "bi bi-truck fs-4",
    //     img2: "bi bi-truck fs-4",
    //     routerLink: "",
    //     linkText: "Logistics",
    //     children: [
    //         {
    //             img1: "bi bi-chevron-left fs-4",
    //             img2: "bi bi-chevron-left fs-4",
    //             routerLink: "#back",
    //             linkText: "Logistics ",
    //             children: [],
    //             style: 'heading'
    //         },
    //         {
    //             img1: "/assets/icons/dark-dashboard/dark-learning.png",
    //             img2: "/assets/icons/white-dashboard/white-learning.png",
    //             routerLink: "#",
    //             linkText: "Menu 1",
    //             children: []
    //         },
    //     ]
    // },
    
];