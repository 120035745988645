<!-- Click trigger for the whole component, there will be another trigger for floating submenu -->
<div class="sidebar-options py-2 cursor-pointer"
  [ngClass]="{'heading': optionInfo.style === 'heading', 
    'divider': optionInfo.routerLink === '#divider',
    'selected': clickedOption === optionInfo.linkText && optionInfo.children.length === 0}" 
  (click)="onOptionClick()">

    <!-- For displaying menu with children / submenu items -->
    <ng-container *ngIf="optionInfo.children.length > 0">

        <!-- When sidebar is in fullsize -->
        <div class="sidebar-parent px-3" *ngIf="!isMinimized">
          <div class="text-primary position-absolute" style="left: 10px;">
            <i *ngIf="clickedParent" class="bi-chevron-up fs-5"></i>
            <i *ngIf="!clickedParent" class="bi-chevron-down fs-5"></i>
          </div>

          <div class="sidebar-options-text">
            <div class="ps-2 me-1">
              <ng-container *ngIf="!optionInfo.img1.includes('png');else iconpng">
                <i class={{optionInfo.img1}} [ngClass]="clickedOption == optionInfo.linkText ? 'sidebar-iconsToggle' : 'sidebar-icons'"></i>
              </ng-container>                    
              </div>
            <div class="sidebar-text">
                {{optionInfo.linkText | translate}}
            </div>
          </div>
        </div>

        <!-- When sidebar is in minimized -->
        <div *ngIf="isMinimized" ngbDropdown 
          #sideDropdownTest="ngbDropdown" placement="right" 
          class="px-2 dropdown-lg">
          <div class="d-flex" ngbDropdownToggle>
              <div class="sidebar-options-text d-flex">
                  <ng-container *ngIf="!optionInfo.img1.includes('png');else iconpng">
                    <i class={{optionInfo.img1}} [ngClass]="clickedOption == optionInfo.linkText ? 'sidebar-iconsToggle' : 'sidebar-icons'"></i>
                  </ng-container>                    
                  <div class="text-primary" >
                    <i class="bi-chevron-right fs-5"></i>
                  </div>
              </div>
          </div>
          <div ngbDropdownMenu class="pt-0 pb-0 mb-0 ms-3" 
            style="border-radius: 0.8rem;">
              <div *ngFor="let item of childrenMenu" 
                  (click)="onOptionClick(item); sideDropdownTest.close()"
                  [ngClass]="{'selected': optionInfo.linkText === item.linkText}"
                  class="d-flex flex-row justify-content-between p-3 px-4 bg-language-select">
                  <div>
                      <ng-container *ngIf="!item.img1.includes('png')">
                        <i [class]="item.img1 + ' text-primary me-2'" [ngClass]="clickedOption == item.linkText ? 'sidebar-iconsToggle' : 'sidebar-icons'"></i>
                      </ng-container>  
                      <ng-container *ngIf="item.img1.includes('png') || item.img1.includes('svg')">
                        <img src="{{item.img1}}" class="me-2">
                      </ng-container>  

                      <span class="fw-bold">{{item.linkText | translate}}</span>
                  </div>
              </div>
          </div>
      </div>
    </ng-container>
    
    <!-- For displaying menu with no children / submenu -->
    <ng-container *ngIf="optionInfo.children.length === 0">
      <ng-container [ngSwitch]="optionInfo.routerLink">
      <ng-container *ngIf="optionInfo.routerLink !== '#back'">
        <div class="d-flex align-items-start sidebar-options-text px-1">
          <div [ngClass]="{'ps-2 me-3': true, 'icon-container': clickedOption === optionInfo.linkText}">
            <ng-container *ngIf="!optionInfo.img1.includes('png') && !optionInfo.img1.includes('svg');else iconpng">
              <i class={{optionInfo.img1}}></i>
            </ng-container>  
          </div>
          <div class="sidebar-text my-auto">
              {{optionInfo.linkText | translate}}
          </div>
        </div>
        </ng-container>
      </ng-container>
    </ng-container>

</div>

<ng-template #iconpng>
  <img src="{{optionInfo.img1}}">
</ng-template>