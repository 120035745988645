import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidebar-buttons',
  templateUrl: './sidebar-buttons.component.html',
  styleUrls: ['./sidebar-buttons.component.css']
})
export class SidebarButtonsComponent implements OnInit {

  @Input() optionInfo: any
  @Input() clickedOption: string = ""
  @Output() onClickEvent = new EventEmitter<string>();

  // onClickStatus: boolean = false

  optionOnClick(){
    // this.onClickStatus = true
    this.onClickEvent.emit(this.optionInfo.linkText)
  }
  constructor() { }

  ngOnInit(): void {
    
  }

}
