import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationService } from 'src/app/services/shared/navigation.service';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.scss']
})
export class SidebarButtonComponent implements OnInit, OnChanges {
  @Input() isMinimized: boolean = false;
  @Input() optionInfo: any;
  @Input() clickedOption: string = "";
  @Input() clickedParent: boolean = false;
  @Input() childrenMenu: any = [];
  @Output() onClickEvent = new EventEmitter<string>();

  filteredResults: any[] = []
  subscribedProjects: any

  constructor(
    private navigationService: NavigationService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.clickedOption){
      this.clickedOption = changes.clickedOption.currentValue;
    }
  }

  async ngOnInit() {
    if (!this.optionInfo.children) {
      this.optionInfo['children'] = [];
    }
    if (!this.optionInfo.style) {
      this.optionInfo['style'] = '';
    }
  }

  onSubmenuClick(value: any) {
    this.onClickEvent.emit(value)
  }

  onOptionClick(data?: any) {
    if(data){
      this.onClickEvent.emit(data)
      this.navigationService.setNavChangesA(data);
    } else {
      this.onClickEvent.emit(this.optionInfo)
      this.navigationService.setNavChangesA(this.optionInfo);
    }
  }
 
}
