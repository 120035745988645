<div ngbDropdown class="dropdown-xxl" [placement]="['bottom-right','bottom']" style="z-index: 200;">
    <button class="btn btn-default position-relative m-1 p-1" style="line-height: 1;" href="#" ngbDropdownToggle>
        <i class="bi-bell fs-4" (click)="requestPushNotifPermission()"></i>
        <span
            *ngIf="unreadNotificationCount > 0"
            [ngbTooltip]="'others.notifications' | translate" 
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fs-8">
                {{unreadNotificationCount}}
          </span>
    </button>

    <div ngbDropdownMenu class="rounded shadow-lg pt-0">
        <div class="row bg-primary mx-0 p-3 mb-2">
            <p class="px-3 pt-2 mb-0 fw-bolder text-white" (click)="notify()">{{'others.notifications' | translate}} 
                <small>({{unreadNotificationCount}} unread)</small>
            </p>
        </div>
        <div class="d-flex justify-content-between">
            <app-table-pagination
                [style]="'notification'"
                [limit]="take"
                [meta]="meta"
                (paginationChange)="onPaginationChange($event)"
                (limitChange)="onLimitChange($event)"
            ></app-table-pagination>
            <button class="me-2 btn btn-outline-primary border-0 fw-bold" 
                (click)="markAllNotificationAsRead()">
                <i class="bi-check-all fs-5 me-2"></i>
                {{'others.mark-read' | translate}}
            </button>
        </div>
        
        <hr class="pb-0 mb-0">
        
        <div class="notification-list">

            <ng-container *ngIf="isLoading">
                <app-loading-indicator></app-loading-indicator>
            </ng-container>
            <ng-container *ngIf="!isLoading">
                <div *ngIf="notificationList.length === 0"
                    class="d-flex flex-column notification-item p-2 pb-0 pt-3 mb-0">
                    <div class="row">
                        <div class="col-1 cursor-pointer">
                            <div class="circle bg-primary ms-2 mt-1"></div>
                        </div>
                        <div class="col-11 ps-4 d-flex flex-column">
                            <div class="d-flex flex-row">
                                <p class="fs-7 mb-3 lh-sm cursor-pointer">
                                    {{'others.no-notification' | translate}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngFor="let item of notificationList" 
                    class="d-flex flex-column notification-item p-2 pb-0 pt-3 mb-0">
                    <div class="row mx-0">
                        <div class="col-1 d-flex cursor-pointer align-items-center justify-content-center" 
                            (click)="viewNotification(item)">
                            <button class="btn rounded-circle cursor-default mb-3 p-1 px-2" 
                                [ngClass]="{'bg-primary': !item.isRead, 'bg-info3': item.isRead}">
                                <i class="bi-bell fs-6" 
                                    [ngClass]="{'text-white': !item.isRead, 'text-dark': item.isRead}"></i>
                            </button>
                        </div>
                        <div class="col-11 ps-4 d-flex flex-column">
                            <div class="d-flex flex-row">
                                <p class="fs-7 mb-0 lh-sm cursor-pointer" (click)="viewNotification(item)">
                                    {{item.body}}
                                </p>
                                <div ngbDropdown placement="left" class="px-2 dropdown-sm hover-visible">
                                    <i class="bi bi-three-dots-vertical" ngbDropdownToggle></i>
                                    <div ngbDropdownMenu>
                                        <!-- <button ngbDropdownItem class="p-3 py-2"
                                            (click)="viewNotification(item)">
                                            View
                                        </button> -->
                                        <button *ngIf="!item.isRead" ngbDropdownItem class="p-3 py-2" 
                                            (click)="updateNotificationStatus(item.id, true)">
                                            {{'others.mark-read' | translate}}
                                        </button>
                                        <button *ngIf="item.isRead" ngbDropdownItem class="p-3 py-2" 
                                            (click)="updateNotificationStatus(item.id, false)">
                                            {{'others.mark-unread' | translate}}
                                        </button>
                                        <!-- <button ngbDropdownItem class="p-3 py-2"><font class="text-danger">Remove</font></button> -->
                                    </div>
                                </div>
                            </div>
                            <p (click)="viewNotification(item)" 
                                class="fs-7 text-muted mb-0">
                                {{item.createdAt * 1000 | timeago:'dd MMM yyyy - HH:mm'}}
                            </p>
                            <hr class="pb-0 mb-0">
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>

</div>