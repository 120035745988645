import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import jsPDF from 'jspdf';
import { Router } from '@angular/router';
import { Site } from 'src/app/interfaces/human-resource/clocking';
import { HttpErrorResponse } from '@angular/common/http';
import { ClockingService, ClockingServiceType } from 'src/app/services/hr/clocking/clocking.service';
import { ErrorHandlerService } from 'src/app/services/utilities/error-handler.service';
import { QRCodeElementType } from 'angularx-qrcode';

@Component({
  selector: 'app-site-qr',
  templateUrl: './site-qr.component.html',
  styleUrls: ['./site-qr.component.css']
})
export class SiteQrComponent implements OnInit {

  textStyle: string = "font-family: Arial, Helvetica, sans-serif !important;";
  siteId: string;
  siteData!: Site;
  public elementType: QRCodeElementType = 'img';
  isLoading = false;

  constructor(
    private clockingService: ClockingService,
    private errorService: ErrorHandlerService,
    private _location: Location,
    private router: Router,
  ) {
    this.siteId = this.router?.url?.split('/').pop() || '';
  }

  ngOnInit(): void {
    this.fetchSiteData();
  }

  async fetchSiteData() {
    const params = '/' + this.siteId;
    const siteDetail = await this.clockingService.list(ClockingServiceType.SITE, '', params);
    siteDetail.subscribe({
      next: (response: any) => {
        this.siteData = response.data;
        this.siteData.dateCreated = this.siteData.dateCreated.toString().length > 10 ? this.siteData.dateCreated : (parseInt(this.siteData.dateCreated) * 1000).toString()
      },
      error: (e: HttpErrorResponse) => {
        this.errorService.handleHttpError(e);
      }
    });
  }

  onExportClick() {
    const pdfContent = document.querySelector("#export") as HTMLInputElement;

    const doc = new jsPDF("landscape", "pt", [150, 300]);
    doc.html(pdfContent,
      {
        margin: 20,
        html2canvas: {
          scale: 0.67,
        }
      })
      .then(() => {
        doc.save('Site QRCode' + this.siteData.title + '.pdf');
      });
  }

  onBackClick() {
    this._location.back();
  }

}
