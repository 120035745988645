<div class="container-fluid" >
  <div class="row" style="height: 100vh;">
    <div class="col p-0 m-0">
      <img *ngIf="stepper === 1" style="object-fit: cover;" class="mb-0 img-fluid h-100" src="/assets/forgot-password-bg.jpg" alt="">
      <img *ngIf="stepper === 2" style="object-fit: cover;" class="mb-0 img-fluid h-100" src="/assets/forgot-password-otp-bg.jpg" alt="">
    </div>
    <div class="col form-container">
      <form class="mb-4 d-flex flex-column align-items-center justify-content-center h-100">
        <div class="d-flex flex-column w-100">
          <h1 class="ps-0 mb-4 fw-bold fs-2">{{'login.forgot-password' | translate}}</h1>

          <ng-container *ngIf="stepper === 1">
            <p class="mb-5 fw-bold text-muted w-75">
              {{'login.reset-line' | translate}} 
            </p>

            <ng-container *ngIf="userNotFoundWarning">
              <div class="alert alert-danger2 w-100" role="alert">
                {{'login.user-not-found' | translate}}
              </div>
            </ng-container>

            <div class="form-group row ms-0 py-2 w-100 mb-5">
              <label class="fw-bold py-2 ps-0">{{'login.username' | translate}} <span class="text-danger fw-normal">*</span></label>
              <div class="col-sm-12 px-0">
                  <div class="input-group" [ngClass]="{'border border-2 border-danger rounded': userNotFoundWarning}">
                    <input type="text" 
                      class="form-control" id="floatingInput" 
                      [placeholder]="'login.username' | translate" #username>
                  </div>
              </div>
            </div>

            <button class="mb-3 btn btn-primary" type="submit" (click)="requestOtpCode(username.value)">
              <div class="d-flex flex-row justify-content-center">
                <span class="fw-bold fs-7">{{'login.request-otp' | translate}}</span> 
                <div *ngIf="isLoading" class="animate-spin">
                  <i class="bi bi-arrow-repeat"></i>
                </div>
              </div>
            </button>
          </ng-container>

          <ng-container *ngIf="stepper === 2">
            <p class="mt-2 mb-5 fw-bold">{{'login.check-email' | translate}}</p>

            <div class="form-group row ms-0 py-2 w-100">
              <label class="fw-bold py-2 ps-0">{{'login.username' | translate}} <span class="text-danger fw-normal">*</span></label>
              <div class="col-sm-12 px-0">
                  <div class="input-group" [ngClass]="{'border border-2 border-danger rounded': noOtpWarning}">
                    <input type="text" 
                    class="form-control" 
                     [placeholder]="'login.username' | translate" #username>
                  </div>
              </div>
            </div>

            <div class="form-group row ms-0 py-2 w-100">
              <label class="fw-bold py-2 ps-0">{{'login.email-address' | translate}} <span class="text-danger fw-normal">*</span></label>
              <div class="col-sm-12 px-0">
                  <div class="input-group" [ngClass]="{'border border-2 border-danger rounded': noOtpWarning}">
                    <input type="email" 
                    class="form-control" 
                     [placeholder]="'login.email' | translate" #email>
                  </div>
              </div>
            </div>

            <div class="form-group row ms-0 py-2 w-100">
              <label class="fw-bold py-2 ps-0">{{'login.otp-code' | translate}} <span class="text-danger fw-normal">*</span></label>
              <div class="col-sm-12 px-0">
                  <div class="input-group" [ngClass]="{'border border-2 border-danger rounded': noOtpWarning}">
                    <input type="text" 
                    class="form-control" 
                     [placeholder]="'login.otp-code' | translate" #otp>
                  </div>
              </div>
            </div>

            <div class="form-group row ms-0 py-2 w-100">
              <label class="fw-bold py-2 ps-0">{{'login.password' | translate}} <span class="text-danger fw-normal">*</span></label>
              <div class="col-sm-12 px-0">
                <div class="input-group" [ngClass]="{'border border-2 border-danger rounded': passwordConfirmWarning}">
                  <input 
                    #password
                    [type]="view['password'] ? 'text' : 'password'" 
                    class="form-control" 
                    id="floatingPassword" 
                    autocomplete="new-password"
                    [placeholder]="'login.enter-your-password' | translate"
                    (keyup)="checkPasswordStrength($event, confirmPassword.value)">
                  <span class="input-group-text border-0 p-2 password-visible-toggle" (click)="toggleViewPassword('password')">
                    <i class="fs-4 text-primary" [ngClass]="{'bi-eye-fill': view['password'], 'bi-eye-slash': !view['password']}"></i>
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group row ms-0 py-2 w-100 mb-4">
              <label class="fw-bold py-2 ps-0">{{'login.confirm-password' | translate}} <span class="text-danger fw-normal">*</span></label>
              <div class="col-sm-12 px-0">
                <div class="input-group" [ngClass]="{'border border-2 border-danger rounded': passwordConfirmWarning}">
                  <input 
                    #confirmPassword
                    [type]="view['confirmPassword'] ? 'text' : 'password'" 
                    class="form-control" 
                    id="floatingPassword" 
                    autocomplete="new-password"
                    [placeholder]="'login.enter-your-password' | translate"
                    (keyup)="checkPasswordStrength($event, confirmPassword.value)">
                  <span class="input-group-text border-0 p-2 password-visible-toggle" (click)="toggleViewPassword('confirmPassword')">
                    <i class="fs-4 text-primary" [ngClass]="{'bi-eye-fill': view['confirmPassword'], 'bi-eye-slash': !view['confirmPassword']}"></i>
                  </span>
                </div>
              </div>
            </div>

            <ng-container *ngIf="passwordConfirmWarning">
              <div class="alert alert-danger w-100" role="alert">
                {{'login.confirm-not-match' | translate}}
              </div>
            </ng-container>

            <ng-container *ngIf="password.value.length > 0">
              <div *ngIf="password.value.length < passwordStrength.minLength
               || passwordStrength.symbols && !checkSpecialChars(password.value)
               || passwordStrength.numbers && !checkNumber(password.value)
               || passwordStrength.uppercase && !checkCapital(password.value)" class="alert alert-danger mt-2 w-100">
                {{'login.password-strength-warning' | translate}}
                <ng-container *ngIf="password.value.length < passwordStrength.minLength">
                   <p class="my-1"><i class="bi-x-lg me-2"></i> {{'login.at-least' | translate}} {{passwordStrength.minLength}} {{'login.characters' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="passwordStrength.symbols && !checkSpecialChars(password.value)">
                   <p class="my-1"><i class="bi-x-lg me-2"></i> {{'login.has-special-char' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="passwordStrength.numbers && !checkNumber(password.value)">
                   <p class="my-1"><i class="bi-x-lg me-2"></i> {{'login.has-number' | translate}}</p>
                </ng-container>
                <ng-container *ngIf="passwordStrength.uppercase && !checkCapital(password.value)">
                   <p class="my-1"><i class="bi-x-lg me-2"></i> {{'login.has-capital' | translate}}</p>
                </ng-container>
              </div>
              <div *ngIf="password.value.length >= passwordStrength.minLength
              || passwordStrength.symbols && checkSpecialChars(password.value)
              || passwordStrength.numbers && checkNumber(password.value)
              || passwordStrength.uppercase && checkCapital(password.value)" class="alert alert-success mt-2 w-100">
                  <ng-container *ngIf="password.value.length >= passwordStrength.minLength">
                    <p class="my-1"><i class="bi-check-lg me-2"></i> {{'login.at-least' | translate}} {{passwordStrength.minLength}} {{'login.characters' | translate}}.</p>
                  </ng-container>
                  <ng-container *ngIf="passwordStrength.symbols && checkSpecialChars(password.value)">
                    <p class="my-1"><i class="bi-check-lg me-2"></i> {{'login.has-special-char' | translate}}</p>
                  </ng-container>
                  <ng-container *ngIf="passwordStrength.numbers && checkNumber(password.value)">
                    <p class="my-1"><i class="bi-check-lg me-2"></i> {{'login.has-number' | translate}}</p>
                  </ng-container>
                  <ng-container *ngIf="passwordStrength.uppercase && checkCapital(password.value)">
                    <p class="my-1"><i class="bi-check-lg me-2"></i> {{'login.has-capital' | translate}}</p>
                  </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="noOtpWarning">
              <div class="alert alert-danger w-100" role="alert">
                {{'login.otp-invalid' | translate}}
              </div>
            </ng-container>

            <ng-container *ngIf="resetSuccess">
              <div class="alert alert-success w-100" role="alert">
                {{'login.password-reset-success' | translate}}
              </div>
            </ng-container>
  
            <button class="w-100 btn btn-lg btn-primary mb-3" type="submit" (click)="submitResetPassword(username.value, email.value, otp.value, password.value, confirmPassword.value)">
              <div class="d-flex flex-row justify-content-center">
                <span class="fw-bold fs-7">{{'login.reset-password' | translate}}</span> 
                <div *ngIf="isLoading" class="animate-spin">
                  <i class="bi bi-arrow-repeat"></i>
                </div>
              </div>
            </button>
          </ng-container>

          <button class="mb-3 btn btn-default border border-2" type="submit" (click)="onNavigateClick('login', false)">
            <div class="d-flex flex-row justify-content-center">
              <span class="fw-bold fs-7">{{'login.back-to-login' | translate}}</span> 
            </div>
          </button>

        </div>
      </form>
    </div>
  </div>
</div>