import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Organisation } from 'src/app/interfaces/masterpass/policy';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  orgEndPoint = "/organisation/";

  constructor(private http: HttpClient) { }

  async getOrganisation(id: string): Promise<Observable<any>> {
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint + "orgUser/" + id);
  }

  async getSelfOrganisation(): Promise<Observable<any>> {
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint + "self");
  }

  xgetOrganisation(id: string): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint + "orgUser/" + id);
  }

  getOrganisations(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint);
  }

  async getPermissionOrganisations(id: string): Promise<Observable<any>> { // Retrieves the organisation that the user has permission to access
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint + "permOrgs/" + id);
  }

  async getOrgDepartments(id: string): Promise<Observable<any>> {
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint + "orgDepartment/" + id);
  }

  async getOrgMembers(id: string): Promise<Observable<any>> {
    return this.http.get<any>(environment.apiUrl + "" + this.orgEndPoint + "orgMembers/" + id);
  }

  async getAllInvolvedOrgMembers(ids: string[]): Promise<Observable<any>> {
    return this.http.post<any>(environment.apiUrl + this.orgEndPoint + "orgMembers", ids);
  }

  async getOrgRoles(id: string): Promise<Observable<any>> {
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint + "orgRoles/" + id);
  }

  async getAOrganisation(id: string): Promise<Observable<any>> {
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint + id);
  }

  async createOrganisation(data: Organisation): Promise<Observable<any>> {
    return this.http.post<any>(environment.apiUrl + this.orgEndPoint, data);
  }

  async updateOrganisation(data: Organisation): Promise<Observable<any>> {
    return this.http.patch<any>(environment.apiUrl + this.orgEndPoint, data);
  }

  async deleteOrganisation(id: string): Promise<Observable<any>> {
    return this.http.delete<any>(environment.apiUrl + this.orgEndPoint + id);
  }

  async updateOrganisationLogo(id: string, data: any): Promise<Observable<any>> {
    return this.http.put<any>(environment.apiUrl + this.orgEndPoint + "logo", data);
  }

  // Organisation - Organisation
  getOrganisationInvite(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint + "invite/");
  }

  getOrganisationInvited(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + this.orgEndPoint + "invited/");
  }

  acceptOrganisationInvite(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + this.orgEndPoint + "invite", data);
  }

  acceptInvite(inviteeId: string, body: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + this.orgEndPoint + "invite/" + inviteeId, body);
  }
}
