import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventSourcePolyfill } from 'event-source-polyfill';

export enum NotificationServiceType {
  NOTIFICATION,
  NOTIFICATION_LISTEN,
}

const NotificationEventChannels = {
  // PayrollNotificationType
  PAYSLIP_RECEIVED: 'PAYSLIP_RECEIVED',
  SOCIAL_SECURITY_REMINDER: 'SOCIAL_SECURITY_REMINDER',
  // MasterPassNotificationType
  PROFILE_UPDATED: 'PROFILE_UPDATED',
  PASSWORD_UPDATED: 'PASSWORD_UPDATED',
  PASSWORD_CHANGED_BY_ADMIN: 'PASSWORD_CHANGED_BY_ADMIN',
  PASSWORD_RESET: 'PASSWORD_RESET',
  PERMISSION_UPDATED: 'PERMISSION_UPDATED',
  DEPARTMENT_PERMISSION_UPDATED: 'DEPARTMENT_PERMISSION_UPDATED',
  ROLE_PERMISSION_UPDATED: 'ROLE_PERMISSION_UPDATED',
  // HumanResourceNotificationType
  LEAVE_REQUESTED: 'LEAVE_REQUESTED',
  LEAVE_REQUEST_UPDATE: 'LEAVE_REQUEST_UPDATE',
  LEAVE_REQUEST_REPLY: 'LEAVE_REQUEST_REPLY',
  CLAIM_REQUESTED: 'CLAIM_REQUESTED',
  CLAIM_REQUEST_UPDATE: 'CLAIM_REQUEST_UPDATE',
  CLAIM_REQUEST_REPLY: 'CLAIM_REQUEST_REPLY',
  ATTENDANCE_ASSISTED: 'ATTENDANCE_ASSISTED',
  // LearningNotificationType
  NEW_COURSE_PUBLISHED: 'NEW_COURSE_PUBLISHED',
  COURSE_REMINDER: 'COURSE_REMINDER',
  // AdminNotificationType
  SYSTEM_INFO: 'SYSTEM_INFO',
}


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private MP_URL: string;
  private listener!: EventSourcePolyfill;
  constructor(
    private httpSvc: HttpClient
  ) {
    this.MP_URL = environment.apiUrl;
  }

  getUrl(serviceType: NotificationServiceType) {
    let url: string = '';
    switch (serviceType) {
      case NotificationServiceType.NOTIFICATION:
        url = this.MP_URL + '/notification';
        break;
      case NotificationServiceType.NOTIFICATION_LISTEN:
        url = this.MP_URL + '/notification/listen';
        break;
      default:
        break;
    }
    return url;
  }

  async create(serviceType: NotificationServiceType, body: any): Promise<Observable<any>> {
    return this.httpSvc.post(this.getUrl(serviceType), body);
  }

  async delete(serviceType: NotificationServiceType, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.delete(this.getUrl(serviceType) + '/' + path);
  }

  async detail(serviceType: NotificationServiceType, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + '/' + path);
  }

  async list(serviceType: NotificationServiceType, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + path);
  }

  async update(serviceType: NotificationServiceType, body: any, path = ''): Promise<Observable<any>> {
    return this.httpSvc.patch(this.getUrl(serviceType) + '/' + path, body);
  }

  listen(serviceType: NotificationServiceType): Observable<any> {
    // add auth token as header to notification listener, default EventSource does not support
    // headers, so using event-source-polyfill npm package
    const token = localStorage.getItem('auth-token') as string;
    this.listener = new EventSourcePolyfill(this.getUrl(serviceType), {
      headers: { 'Authorization': 'Bearer ' + JSON.parse(token) }
    });

    return new Observable(observer => {
      function parseEvent(event: string, data: any) {
        // console.log(event, data);
        const messageData: any = {
          type: event,
          event: data
        }
        observer.next(messageData);
      }

      // create listener for all listed notification channels
      for (const [key, channel] of Object.entries(NotificationEventChannels)) {
        this.listener.addEventListener(channel, (event: any) => { parseEvent(channel, event); });
      }

      this.listener.addEventListener("open", (event: any) => { parseEvent('open', event) });
      this.listener.addEventListener("message", (event: any) => { parseEvent('message', event) });
      this.listener.addEventListener("error", (event: any) => { parseEvent('error', event) });
    });
  }

  closeListener() {
    this.listener.close()
  }
}

