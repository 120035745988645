import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { InformationModalComponent } from 'src/app/shared/information-modal/information-modal.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private toastrService: ToastrService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve, reject) => {
      if (window.localStorage.getItem('auth-user')) {
        const authUser = JSON.parse(window.localStorage.getItem('auth-user') || '');
        const jwt = this.parseJwt(window.localStorage.getItem('auth-token') || '');
        const rememberMe = authUser.remember_me || false;
        var currentTime = new Date().getTime() / 1000;

        if (rememberMe) {
          if (currentTime > jwt.exp) {
            /* expired */
            this.router.navigate(['/login'])
            resolve(false)
          } else {
            resolve(true)
          }
        } else {
          // valid for 30 days
          const validLimit = new Date(jwt.iat).getTime() + 30 * 24 * 60 * 60;
          // console.log(validLimit)
          if (currentTime > validLimit) {
            /* expired */
            const modalRef = this.modalService.open(InformationModalComponent, { size: 'md', windowClass: 'modal-bg-transparent' });
            modalRef.componentInstance.labelHeader = 'Notice';
            modalRef.componentInstance.modalLabel = 'Your session has expired, you will be redirected to the login page.'
            modalRef.closed.subscribe(event => {
              if (event === true) {
                this.router.navigate(['/login'])
                this.authService.logout();
                resolve(false)
              }
            })

          } else {
            resolve(true)
          }
        }
      }
      else {
        this.router.navigate(['/login'])
        resolve(false)
      }

    })
  }

  parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

}
