<div (click)="onOptionClick()">
  <ng-container *ngIf="optionInfo?.children?.length > 0">
    <div class="sidebar-parent">
        <div class="sidebar-text fs-6 w-100 text-start" style="display: block;">
            <ng-container *ngIf="!optionInfo.img1.includes('png') && !optionInfo.img1.includes('svg');else iconpng">
              <i [class]="optionInfo.img1 + ' me-3'"></i>
            </ng-container>  
            {{optionInfo.linkText | translate}}    
        </div>
    </div>
  </ng-container>

  <ng-container *ngIf="optionInfo?.children?.length === 0">
    <ng-container *ngIf="optionInfo.routerLink === '#back'">
      <div class="sidebar-parent">
        <div class="sidebar-text fs-6 w-100 text-start" style="display: block;">
            {{optionInfo.linkText | translate}}    
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="optionInfo.routerLink !== '#back'">
        <a [routerLink]="optionInfo.routerLink" 
          routerLinkActive="active" class="fs-6 w-100 text-start" style="display: block;">
          <ng-container *ngIf="!optionInfo.img1.includes('png') && !optionInfo.img1.includes('svg');else iconpng">
            <i [class]="optionInfo.img1 + ' me-3'"></i>
          </ng-container>  
          {{optionInfo.linkText | translate}}
        </a>
    </ng-container>
  </ng-container>
</div>


<ng-template #iconpng>
  <img [ngClass]="clickedOption == optionInfo.linkText ? 'sidebar-iconsToggle me-3' : 'sidebar-icons me-3'" src="{{optionInfo.img1}}">
</ng-template>