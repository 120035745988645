import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SidebarMenu } from 'src/app/home/navigation/sidebar/sidebar.component';
import { environment } from 'src/environments/environment';

export const RECENT_MENU_KEY = 'onepass-recent-menu'
export const FAVORITE_MENU_KEY = 'onepass-favorite-menu'

export enum MasterpassServiceType {
  DASHBOARD_SETTINGS,
  LANGUAGE_LIST,
  LANGUAGE_SELF,
  NOTES
}

export type SavedMenu = {
  userId: string,
  menu: SidebarMenu[]
}

@Injectable({
  providedIn: 'root'
})
export class MasterpassService {

  private MP_URL: string;
  constructor(
    private httpSvc: HttpClient
  ) {
    this.MP_URL = environment.apiUrl;
  }

  getUrl(serviceType: MasterpassServiceType) {
    let url: string = '';
    switch (serviceType) {
      case MasterpassServiceType.DASHBOARD_SETTINGS:
        url = this.MP_URL + '/dashboard-settings';
        break;
      case MasterpassServiceType.LANGUAGE_LIST:
        url = this.MP_URL + '/languages/list';
        break;
      case MasterpassServiceType.LANGUAGE_SELF:
        url = this.MP_URL + '/languages/self';
        break;
      case MasterpassServiceType.NOTES:
        url = this.MP_URL + '/notes';
        break;
      default:
        break;
    }
    return url;
  }

  async create(serviceType: MasterpassServiceType, body: any): Promise<Observable<any>> {
    return this.httpSvc.post(this.getUrl(serviceType), body);
  }

  async delete(serviceType: MasterpassServiceType, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.delete(this.getUrl(serviceType) + '/' + path);
  }

  async detail(serviceType: MasterpassServiceType, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + '/' + path);
  }

  async list(serviceType: MasterpassServiceType, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + path);
  }

  async update(serviceType: MasterpassServiceType, body: any, path = ''): Promise<Observable<any>> {
    return this.httpSvc.put(this.getUrl(serviceType) + '/' + path, body);
  }

  async patch(serviceType: MasterpassServiceType, body: any, path = ''): Promise<Observable<any>> {
    return this.httpSvc.patch(this.getUrl(serviceType) + '/' + path, body);
  }

  public getRecentMenu(): SavedMenu[] | null {
    const data = window.localStorage.getItem(RECENT_MENU_KEY);
    if (data) return JSON.parse(data);
    return null;
  }

  public setRecentMenu(data:SavedMenu[]){
    if (!data) return;

    window.localStorage.removeItem(RECENT_MENU_KEY);
    window.localStorage.setItem(RECENT_MENU_KEY, JSON.stringify(data));
  }

  public getFavoriteMenu(): SavedMenu[] | null {
    const data = window.localStorage.getItem(FAVORITE_MENU_KEY);
    if (data) return JSON.parse(data);
    return null;
  }

  public setFavoriteMenu(data:SavedMenu[]){
    if (!data) return;

    window.localStorage.removeItem(FAVORITE_MENU_KEY);
    window.localStorage.setItem(FAVORITE_MENU_KEY, JSON.stringify(data));
  }
}
