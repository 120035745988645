<ng-container *ngIf="version===3">
    <div class="w-100 row mx-0 px-0 my-auto">
        <div class="row col-xs-12 col-lg-auto px-0 mt-4 mx-0 mb-3">
            <div class="col-xs-6 col-sm-6 col-lg-6 d-flex align-items-center justify-content-start">
                <ng-container *ngIf="!hideLimit">
                    <span class="align-self-center fs-7 text-muted text-nowrap">
                        {{'others.items-per-page' | translate}}: 
                    </span>
                    <select class="border-0 ms-1 me-2 text-dark"
                        (change)="onPageLimitChange($event)">
                        <option *ngIf="shortTable" [selected]="limit === 5" value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </ng-container>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 d-flex flex-row-reverse align-items-center">
                <button class="btn btn-default text-primary" 
                    style="-webkit-text-stroke: 2px;"
                    [disabled]="meta ? !meta.hasNextPage : false" (click)="onNextPageClick()"> 
                    <i class="bi bi-chevron-right"></i>
                </button>
                <button class="btn btn-default text-primary"
                    style="-webkit-text-stroke: 2px;"
                    [disabled]="meta ? !meta.hasPreviousPage : false" (click)="onPrevPageClick()"> 
                    <i class="bi bi-chevron-left"></i>
                </button>
                <span class="fs-7 mx-2 text-nowrap" *ngIf="rows"> <!-- YZ's Hotfix for when table renders before data is loaded -->
                    {{ rowCountFrom }} 
                    - {{ rowCountTo }} 
                    <ng-container *ngIf="!tokenPage">
                        of {{ rowCountTotal }}
                    </ng-container>
                </span>
            </div>
        </div>

        <ng-container *ngIf="!addButton">
            <div class="col-lg-auto col-xs-12 mt-2 px-0">
                <ng-container
                    *ngIf="tableActionButtonPassBy"
                    [ngTemplateOutlet]="tableActionButtonPassBy">
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="style === 'table'">
    <div class="w-100 my-auto">
        <div class="d-flex flex-row justify-content-end">
            <button *ngIf="addButton" 
                style="min-width: 120px;"
                class="btn btn-primary btn-block btn-add float-end mx-2" 
                (click)="addButtonClick.emit()">
                {{buttonLabel === 'New' ? ' + ' + buttonLabel : buttonLabel}}
            </button>
            <ng-container *ngIf="!addButton">
                <ng-container
                    *ngIf="tableActionButtonPassBy"
                    [ngTemplateOutlet]="tableActionButtonPassBy">
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="style === 'notification'">
    <div class="w-100 my-auto">
        <div class="d-flex flex-row">
            <button class="btn btn-default btn-xs" [disabled]="meta ? !meta.hasPreviousPage : false" (click)="onPrevPageClick()"> <i
                class="bi bi-chevron-left"></i></button>
            <button class="btn btn-default btn-xs" [disabled]="meta ? !meta.hasNextPage : false" (click)="onNextPageClick()"> <i
                class="bi bi-chevron-right"></i></button>
            <span class="align-self-center px-2">{{'others.show' | translate}}: </span>
            <select class="form-control form-select py-0 px-2 rounded-pill"
                (change)="onPageLimitChange($event)"
                style="width:60px">
                <option value="5">5</option>
                <option value="10">10</option>
            </select>
        </div>
    </div>
</ng-container>