import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.css']
})
export class NavigationTabsComponent implements OnInit {
  @Input() activeTab: number = 0;
  @Input() links: any = [];
  @Output() onMenuChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  change(data:any){
    this.onMenuChange.emit(data)
  }

}
