@if (!isHideTopbar) {
  <div
    class="topbar ms-5"
    style="position: absolute; top: 0; right: 10px; z-index: 100;">
    <ul>
      <!-- Organisation Switcher -->
      <li class="mt-1">
        @if (!orgLoading) {
          <select
            class="form-select bg-light py-2 px-5 rounded border-0"
            (change)="onOrgChange($event)">
            @for (org of (organisationsList); track org) {
              <option
                [selected]="org.id === currentOrganisation?.id"
                [value]="org | json">
                {{org.name}}
              </option>
            }
          </select>
        }
      </li>
      <!-- Notification Dropdown -->
      <li>
        <app-notification></app-notification>
      </li>
      <!-- Profile Dropdown -->
      <li>
        <div ngbDropdown #profileDropdown="ngbDropdown" class="dropdown-lg">
          <button class="btn btn-default m-1 p-1" style="line-height: 1;" href="#" ngbDropdownToggle>
            <i class="bi-person fs-4"></i>
          </button>
          <div ngbDropdownMenu class="bg-light pb-0">
            <div class="bg-dropdown-profile d-flex flex-row-reverse align-items-center">
              <img [src]="userData?.imgUrl"
                [default]="'../../../../../assets/identicon.png'"
                style="width: 70px;height: 70px; opacity: 1; z-index: 3; top: 20px; left: 20px;" alt=""
                class="profile-pic position-absolute border border-white mb-3 mt-3">
              <p class="mb-0 fw-bolder text-white me-3 w-50">
                {{userData?.name}} -
                {{userData?.role?.roles}}
                @if (userData.owner) {
                  <br>
                  }
                  @if (userData.owner) {
                    <span class="badge bg-primary">
                      {{'navigation.owner' | translate}}
                    </span>
                  }
                </p>
              </div>
              <div class="position-relative" style="z-index: 1; margin-top:110px">
                <div class="d-flex flex-column align-items-center mx-0">
                  <div (click)="onNavigateClick('profile', false); profileDropdown.close();"
                    class="d-flex flex-row bg-navbar-action p-4">
                    <i class="bi bi-person me-3 fs-5"></i>
                    <span class="fw-bolder">{{'navigation.profile' | translate}}</span>
                  </div>
                  <!-- <div (click)="onNavigateClick('setting'); profileDropdown.close();" class="d-flex flex-row justify-content-between p-4 rounded-pill bg-navbar-action" style="width: 12rem">
                  <div>
                    <i class="bi bi-gear me-2"></i>
                    <span class="fw-bolder">Settings</span>
                  </div>
                  <i class="bi bi-chevron-right"></i>
                </div> -->
                <div (click)="onReportErrorClick(); profileDropdown.close();"
                  class="d-flex flex-row bg-navbar-action p-4">
                  <i class="bi bi-exclamation-octagon me-3 fs-5"></i>
                  <span class="fw-bolder">{{'navigation.report-error' | translate}}</span>
                </div>
                <div (click)="onNavigateClick('user-guide', false); profileDropdown.close();"
                  class="d-flex flex-row bg-navbar-action p-4">
                  <i class="bi bi-question-circle me-3 fs-5"></i>
                  <span class="fw-bolder">{{'navigation.help' | translate}}</span>
                </div>
                <div (click)="onLogoutClick()"
                  class="d-flex flex-row bg-navbar-action p-4">
                  <i class="bi bi-box-arrow-right me-3 fs-5"></i>
                  <span class="fw-bolder">{{'navigation.logout' | translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
}

<!-- Sidebar -->
<app-sidebar></app-sidebar>

<!-- Navigation Modal -> only in mobile view -->
<div id="toggle-mobile-menu" style="z-index: 1000;" (click)="openModal()">
  <img src="../../assets/icons/icons8-menu-24.png">
</div>

<!-- The Modal -->
<div id="sidebar-modal" class="sidebar-modal">
  <div class="sidebar-modal-content p-0">
    <div class="modal-header bg-info2 p-4 pb-2 text-primary">
      <h2 class="modal-title m-0 fw-bold" id="clockInLabel">
        {{'navigation.navigation' | translate}}
      </h2>
      <button type="button" class="btn-close p-0 m-0" (click)="closeModal()"></button>
    </div>
    <div class="sidebar-modal-list px-4">
      <ul>
        @for (optionInfo of visibleSystemOption; track optionInfo) {
          <li class="sidebar-modal-list-item my-2"
            >
            <app-navigation-modal-button
              [optionInfo]="optionInfo"
              [clickedOption]="clickedOption"
              (onClickEvent)="onClickEvent($event, true)">
            </app-navigation-modal-button>
          </li>
        }

        @if (visibleSystemOptionChildren[findParentIndex(clickedOption)]?.length > 0) {
          <li class="sidebar-modal-list-item my-2 pb-2 px-3 fs-7">
            <div class="sidebar-parent" (click)="visibleSystemOptionChildren = []">
              <div class="sidebar-text fw-bold w-100 text-start">
                <i class="bi-chevron-up"></i>
                {{'others.close' | translate}}
              </div>
            </div>
          </li>
        }
        @for (optionInfo of visibleSystemOptionChildren[findParentIndex(clickedOption)]; track optionInfo) {
          <li class="sidebar-modal-list-item my-2 pb-2 px-3 fs-7"
            >
            <app-navigation-modal-button
              [optionInfo]="optionInfo"
              [clickedOption]="clickedOption"
              (onClickEvent)="onClickEvent($event, true)">
            </app-navigation-modal-button>
          </li>
        }
      </ul>
    </div>
  </div>
</div>