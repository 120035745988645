<div class="bg-white rounded">
    <div class="modal-header bg-info2 p-4 pb-2 text-primary">
        <h2 class="modal-title fw-bold">{{'clocking.comments' | translate}}</h2>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body px-4">

        <ng-container *ngIf="data.comments.length === 0">
            <div class="col-sm-12 text-center mb-4">
                <i class="bi-chat fs-1 mb-4"></i><br><br>
                <label class="fw-bold f-6">No message here yet.</label>
            </div>
        </ng-container>

        <ng-container *ngFor="let comment of data.comments">
            <div class="col-md-12 mb-2" *ngIf="comment.user?.id !== currentUserId;else userComment">
                <div class="rounded bg-info2 p-3">
                    <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-row">
                            <img
                            [src]="comment.user?.imgUrl"
                            [default]="'../../../../../assets/identicon.png'"
                            alt=""
                            class="profile-pic position-relative me-1">
                            &nbsp;
                            <span class="fw-bolder">{{ comment.user?.name || '-'}}</span>
                        </div>
                        <span class="fs-7 text-mute"> {{comment.createdAt * 1000 | date:'HH:mm, dd MMM yyyy'}}</span>
                    </div>
                    <p class="mb-0 mt-2">{{ comment.comment}}</p>
                </div>
            </div>
            <ng-template #userComment>
                <div class="col-md-12 mb-2">
                    <div class="rounded bg-success2 p-3">
                        <div class="d-flex flex-row-reverse justify-content-between">
                            <div class="d-flex flex-row-reverse">
                                <img
                                [src]="comment.user?.imgUrl"
                                [default]="'../../../../../assets/identicon.png'"
                                alt=""
                                class="profile-pic position-relative me-1">
                                &nbsp;
                                <span class="fw-bolder">{{ comment.user?.name || '-'}}</span>
                            </div>
                            <span class="fs-7 text-mute"> {{comment.createdAt * 1000 | date:'HH:mm, dd MMM yyyy'}}</span>
                        </div>
                        <p class="mb-0 mt-2 text-end">{{ comment.comment}}</p>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <ng-container >
            <div class="col-md-12 mt-3 d-flex flex-row">
                <input 
                    type="text" 
                    class="form-control" 
                    placeholder="Write a reply ..." 
                    [(ngModel)]="textbox">
                <button (click)="onSendReplyClick()" class="btn btn-primary btn-sm rounded-circle ms-3"><i class="bi-send-fill"></i></button>
            </div>
            <div class="alert alert-danger mt-2" *ngIf="showMessage">
                Your message has not been sent yet.
            </div>
        </ng-container>
    </div>
    <div class="modal-footer bg-light p-3">
        <button class="btn btn-outline-primary ms-2 p-3" 
            style="width: 120px;" 
            (click)="confirm()">
            {{'others.close' | translate}}
        </button>
    </div>

</div>