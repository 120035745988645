import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { Project, Organisation } from 'src/app/interfaces/masterpass/policy';

@Injectable({
  providedIn: 'root'
})
export class ProjectClientService {
  private _currentUserProject: Observable<Project> | undefined;
  public _currentUserOrganisation: Observable<Organisation> | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public setCurrentProject(project: Project): void {
    this._currentUserProject = of(project);
    localStorage.setItem('current-project', JSON.stringify(project));
  }

  public getCurrentUserProject(): Observable<Project> | undefined {
    this._currentUserProject = of(
      localStorage.getItem('current-project') ?
        JSON.parse(localStorage.getItem('current-project')!) : undefined
    )
    if (this._currentUserProject) {
      return this._currentUserProject;
    } else {
      this.router.navigate(['../../home'], { relativeTo: this.route });
      return undefined;
    }
  }

  public clearCurrentuserOrganisation(): void {
    localStorage.removeItem('current-organisation');
  }

  public setCurrentuserOrganisation(organisation: Organisation): void {
    this._currentUserOrganisation = of(organisation);
    localStorage.setItem('current-organisation', JSON.stringify(organisation));
  }

  public getCurrentUserOrganisation(): Observable<Organisation> | undefined {
    this._currentUserOrganisation = of(
      (localStorage.getItem('current-organisation') &&
        localStorage.getItem('current-organisation') != 'undefined') ?
        JSON.parse(localStorage.getItem('current-organisation')!) : undefined
    )

    if (this._currentUserOrganisation) {
      return this._currentUserOrganisation;
    } else {
      // this.router.navigate(['../../home'], { relativeTo: this.route });
      return undefined;
    }
  }

}
