import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum ClockingServiceType {
  ATTENDANCE,
  ATTENDANCE_SELF,
  ATTENDANCE_SETTINGS,
  SITE,
  ORGANISATION_SITE,
  CLOCKING_ASSIGNMENT,
  USER_ATTENDANCE_SETTINGS
}

@Injectable({
  providedIn: 'root'
})
export class ClockingService {

  private API_URL: string;
  constructor(
    private httpSvc: HttpClient
  ) {
    this.API_URL = environment.apiUrl;
  }

  getUrl(serviceType: ClockingServiceType) {
    let url: string = '';
    switch (serviceType) {
      case ClockingServiceType.ATTENDANCE:
        url = this.API_URL + '/attendance';
        break;
      case ClockingServiceType.ATTENDANCE_SELF:
        url = this.API_URL + '/attendance/self';
        break;
      case ClockingServiceType.ATTENDANCE_SETTINGS:
        url = this.API_URL + '/attendance-settings';
        break;
      case ClockingServiceType.SITE:
        url = this.API_URL + '/site';
        break;
      case ClockingServiceType.ORGANISATION_SITE:
        url = this.API_URL + '/site/organisation';
        break;
      case ClockingServiceType.CLOCKING_ASSIGNMENT:
        url = this.API_URL + '/schedule/assigned/officer/';
        break;
      case ClockingServiceType.USER_ATTENDANCE_SETTINGS:
        url = this.API_URL + '/users-attendance-settings';
        break;
      default:
        break;
    }
    return url;
  }

  async create(serviceType: ClockingServiceType, body: any, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.post(this.getUrl(serviceType) + path, body);
  }

  async delete(serviceType: ClockingServiceType, params?: any, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.delete(this.getUrl(serviceType) + path, params);
  }

  async detail(serviceType: ClockingServiceType, params?: any): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + (params !== undefined ? params : ''));
  }

  async list(serviceType: ClockingServiceType, params?: any, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + path, params);
  }

  async update(serviceType: ClockingServiceType, body: any, path = ''): Promise<Observable<any>> {
    return this.httpSvc.patch(this.getUrl(serviceType) + path, body);
  }

  async searchLocation(search:string, proximity:string, country: string = 'sg', ){
    let params = {
      'q': search,
      'proximity': proximity,
      'country': country,
      'access_token': environment.mapboxToken
    }

    return this.httpSvc.get('https://api.mapbox.com/search/searchbox/v1/forward', { params: params})
  }

  getSingleAttendance(id:string){
    return this.httpSvc.get((this.getUrl(ClockingServiceType.ATTENDANCE) + '/' + id));
  }
}
