import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum PayrollServiceType {
  PAYROLL,
  PAYSLIP,
  PAYSLIP_COMPONENT,
  PAYSLIP_COMPONENT_TAX,
  PAY_RATE,
  PAY_COMPONENT,
  PAYROLL_TYPE,
  EMPLOYEE_SETTING
}

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  private API_URL: string;
  constructor(
    private httpSvc: HttpClient
  ) {
    this.API_URL = environment.apiUrl + '/payroll';
  }

  getUrl(serviceType: PayrollServiceType, payrollId: string = '', payslipId: string = '') {
    let url: string = '';
    switch (serviceType) {
      case PayrollServiceType.PAYROLL:
        url = this.API_URL;
        break;
      case PayrollServiceType.PAYSLIP:
        url = this.API_URL + `/${payrollId}/payslip/${payslipId}`;
        break;
      case PayrollServiceType.PAYSLIP_COMPONENT:
        url = this.API_URL + `/${payrollId}/payslip/${payslipId}/component`;
        break;
      case PayrollServiceType.PAYSLIP_COMPONENT_TAX:
        url = this.API_URL + `/${payrollId}/payslip/${payslipId}/component/generate`;
        break;
      case PayrollServiceType.PAY_RATE:
        url = this.API_URL + '-config/pay-rate';
        break;
      case PayrollServiceType.PAY_COMPONENT:
        url = this.API_URL + '-config/pay-component-config';
        break;
      case PayrollServiceType.PAYROLL_TYPE:
        url = this.API_URL + '-config/payroll-type';
        break;
      case PayrollServiceType.EMPLOYEE_SETTING:
        url = this.API_URL + '-config/employee-setting';
        break;
      default:
        break;
    }
    return url;
  }

  async create(serviceType: PayrollServiceType, body: any, path: string = '', payrollId: string = '', payslipId: string = ''): Promise<Observable<any>> {
    return this.httpSvc.post(this.getUrl(serviceType, payrollId, payslipId) + path, body);
  }

  async delete(serviceType: PayrollServiceType, params?: any, path: string = '', payrollId: string = '', payslipId: string = ''): Promise<Observable<any>> {
    return this.httpSvc.delete(this.getUrl(serviceType, payrollId, payslipId) + '/' + path, params);
  }

  async detail(serviceType: PayrollServiceType, params?: any): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + '/' + (params !== undefined ? params : ''));
  }

  async list(serviceType: PayrollServiceType, params?: any, path: string = '', payrollId: string = '', payslipId: string = ''): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType, payrollId, payslipId) + '/' + path, params);
  }

  async update(serviceType: PayrollServiceType, body: any, path = '', payrollId: string = '', payslipId: string = ''): Promise<Observable<any>> {
    return this.httpSvc.patch(this.getUrl(serviceType, payrollId, payslipId) + '/' + path, body);
  }

  async put(serviceType: PayrollServiceType, body: any, path = '', payrollId: string = '', payslipId: string = ''): Promise<Observable<any>> {
    return this.httpSvc.put(this.getUrl(serviceType, payrollId, payslipId) + '/' + path, body);
  }
}
