import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from 'src/app/services/shared/navigation.service';

@Component({
  selector: 'app-navigation-modal-button',
  templateUrl: './navigation-modal-button.component.html',
  styleUrls: ['./navigation-modal-button.component.scss']
})
export class NavigationModalButtonComponent implements OnInit {
  @Input() optionInfo: any
  @Input() clickedOption: string = ""
  @Input() clickedParent: boolean = false;
  @Input() childrenMenu: any = [];
  @Output() onClickEvent = new EventEmitter<string>();

  constructor(
    private navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
  }

  onSubmenuClick(value: any) {
    this.onClickEvent.emit(value)
  }

  onOptionClick() {
    this.onClickEvent.emit(this.optionInfo)
    this.navigationService.setNavChangesA(this.optionInfo);
  }

}
