<div class="container p-4 mt-4">
    <div class="row mb-4">
        <div class="col-2"></div>
        <div class="col-8">
            <h3 class="fs-2 fw-semibold text-dark text-uppercase mb-6" style="letter-spacing: 1px;">
                Privacy Policy
            </h3>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col-2"></div>
        <div class="col-8">
            <p>
                Superink Pte Ltd. built the Onepass ERP app as
                a Commercial app. This SERVICE is provided by
                Superink Pte Ltd. and is intended for use as is.
            </p>
            <p>
                This page is used to inform visitors regarding our
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service.
            </p>
            <p>
                If you choose to use our Service, then you agree to
                the collection and use of information in relation to this
                policy. The Personal Information that we collect is
                used for providing and improving the Service. We will not use or share your information with
                anyone except as described in this Privacy Policy.
            </p>
            <p>
                The terms used in this Privacy Policy have the same meanings
                as in our Terms and Conditions, which are accessible at
                Onepass ERP unless otherwise defined in this Privacy Policy.
            </p>
            <p><strong>Information Collection and Use</strong></p>
            <p>
                For a better experience, while using our Service, we
                may require you to provide us with certain personally
                identifiable information. The information that
                we request will be retained by us and used as described in this privacy policy.
            </p> <!---->

            <p><strong>Personal Data</strong></p>
            <p>
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:
            </p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Cookies and Usage Data</li>
            </ul>
            <p><strong>Usage Data</strong></p>
            <p>
                We may also collect information how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
            </p>
            <p>
                When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
            </p>
            <p><strong>Tracking & Cookies Data</strong></p>
            <p>
                We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
            </p>
            <p>
                Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
            </p>
            <p>
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
            </p>
            <p>
              This Service does not use these “cookies” explicitly. However,
              the app may use third-party code and libraries that use
              “cookies” to collect information and improve their services.
              You have the option to either accept or refuse these cookies
              and know when a cookie is being sent to your device. If you
              choose to refuse our cookies, you may not be able to use some
              portions of this Service.
            </p>
            <p><strong>Use of Data</strong></p>
            <p>Superink Pte Ltd. uses the collected data for various purposes:</p>
            <ul>
                <li>To provide and maintain the Service</li>
                <li>To notify you about changes to our Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                <li>To provide customer care and support</li>
                <li>To provide analysis or valuable information so that we can improve the Service</li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent and address technical issues</li>
            </ul>
            <p><strong>Transfer Of Data</strong></p>
            <p>
                Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
            </p>
            <p>
                If you are located outside Singapore and choose to provide information to us, please note that we transfer the data, including Personal Data, to Singapore and process it there.
            </p>
            <p>
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
            </p>
            <p>
                Superink Pte Ltd. will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
            </p>
            <p><strong>Service Providers</strong></p>
            <p>
                We may employ third-party companies and
                individuals due to the following reasons:
            </p>
            <ul>
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <p>
                We want to inform users of this Service
                that these third parties have access to their Personal
                Information. The reason is to perform the tasks assigned to
                them on our behalf. However, they are obligated not to
                disclose or use the information for any other purpose.
            </p> <p><strong>Security</strong></p>
            <p>
                We value your trust in providing us your
                Personal Information, thus we are striving to use commercially
                acceptable means of protecting it. But remember that no method
                of transmission over the internet, or method of electronic
                storage is 100% secure and reliable, and we cannot
                guarantee its absolute security.
            </p>
            <p><strong>Links to Other Sites</strong></p>
            <p>
                This Service may contain links to other sites. If you click on
                a third-party link, you will be directed to that site. Note
                that these external sites are not operated by us.
                Therefore, we strongly advise you to review the
                Privacy Policy of these websites. We have
                no control over and assume no responsibility for the content,
                privacy policies, or practices of any third-party sites or
                services.
            </p> <p><strong>Children’s Privacy</strong></p> <!---->
            <div>
                <p>
                We do not knowingly collect personally
                identifiable information from children. We
                encourage all children to never submit any personally
                identifiable information through
                the Application and/or Services.
                We encourage parents and legal guardians to monitor
                their children's Internet usage and to help enforce this Policy by instructing
                their children never to provide personally identifiable information through the Application and/or Services without their permission. If you have reason to believe that a child
                has provided personally identifiable information to us through the Application and/or Services,
                please contact us. You must also be at least 16 years of age to consent to the processing
                of your personally identifiable information in your country (in some countries we may allow your parent
                or guardian to do so on your behalf).
                </p>
            </div>
            <p><strong>Changes to This Privacy Policy</strong></p>
            <p>
                We may update our Privacy Policy from
                time to time. Thus, you are advised to review this page
                periodically for any changes. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
            </p>
            <p>This policy is effective as of 07 March, 2023</p>
            <p><strong>Contact Us</strong></p>
            <p>
                If you have any questions or suggestions about our
                Privacy Policy, do not hesitate to contact us at
                <a href="https://www.superink.com.sg/">https://www.superink.com.sg/</a>.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
        <a routerLink="/">
            <button class="btn btn-dark fs-6 px-4 py-3 mx-2">
                Go back
            </button>
        </a>
        </div>
    </div>
</div>

<div class="footer text-center mt-2 pt-3">
    <p class="text-muted">&copy; Superink Pte Ltd. All Rights Reserved. {{getCurrentYear()}}</p>
</div>
