<ng-container *ngIf="!isFullScreenImage">
    <div class="bg-white rounded">
        <div class="modal-header bg-info2 p-4 pb-2 text-primary">
            <h1 class="modal-title fw-bold">{{'claims.documents' | translate}}</h1>
        </div>
        
        <div class="modal-body p-0">
            <div class="row">
                <div class="col-md-12" [ngClass]="{'p-0': fileExt === 'pdf'}">
                    <ng-container *ngIf="fileUrl">
                        <div *ngIf="fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png'" class="image-holder d-flex flex-column align-items-center">
                            <img 
                                [ngClass]="{
                                    'rotate-0': rotate == 0, 
                                    'rotate-90': rotate == 90, 
                                    'rotate-180': rotate == 180, 
                                    'rotate-270': rotate == 270
                                }"
                                [src]="fileUrl" 
                                class="margin-top-10 p-1 px-2 img-fluid" 
                                style="max-height:600px"
                            >
                        </div>

                        <div *ngIf="fileExt === 'pdf'" class="image-holder px-1 ms-2 d-flex flex-column align-items-center">
                            <iframe
                                [src]="fileUrl"
                                width="100%" height="600px"></iframe>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!fileUrl">
                        {{'others.no-file' | translate}}
                    </ng-container>
                </div>
            </div>
        </div>
        
        <div class="modal-footer bg-light p-3">
            <button type="button" 
                style="width: 120px;" 
                class="btn btn-primary p-3 fw-bold" 
                (click)="onCancel()">{{ cancelBtnLabel | translate}}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isFullScreenImage">
    <div class="">
        <div class="modal-header">
        </div>
        
        <div class="modal-body p-0">
            <div class="row">
                <div class="col-md-12" [ngClass]="{'p-0': fileExt === 'pdf'}">
                    <ng-container *ngIf="fileUrl">
                        <div *ngIf="fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png'" class="image-holder d-flex flex-column align-items-center">
                            <img 
                                [ngClass]="{
                                    'rotate-0': rotate == 0, 
                                    'rotate-90': rotate == 90, 
                                    'rotate-180': rotate == 180, 
                                    'rotate-270': rotate == 270
                                }"
                                [src]="fileUrl" 
                                class="margin-top-10 p-1 px-2 img-fluid" 
                                style="max-height:900px"
                            >
                        </div>

                        <div *ngIf="fileExt === 'pdf'" class="image-holder px-1 ms-2 d-flex flex-column align-items-center">
                            <iframe
                                [src]="fileUrl"
                                width="100%" height="900px"></iframe>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!fileUrl">
                        {{'others.no-file' | translate}}
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>