import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

/* The FileDragDropDirective class is a TypeScript directive that handles the loading and error events
of an img element, allowing for a default image to be displayed in case of an error. */
@Directive({
  selector: '[fileDragDrop]'
})
export class FileDragDropDirective {
//@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
@Output() private filesChangeEmiter : EventEmitter<any> = new EventEmitter();
//@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
@HostBinding('style.background') private background = '#fff';
@HostBinding('style.border') private borderStyle = '2px dashed';
@HostBinding('style.border-color') private borderColor = '#e0e0e0';
@HostBinding('style.border-radius') private borderRadius = '5px';

constructor() { }

@HostListener('dragover', ['$event']) public onDragOver(evt:any){
  evt.preventDefault();
  evt.stopPropagation();
  this.background = '#f3f8ff';
  this.borderColor = 'cadetblue';
  this.borderStyle = '3px solid';
}

@HostListener('dragleave', ['$event']) public onDragLeave(evt:any){
  evt.preventDefault();
  evt.stopPropagation();
  this.background = '#fff';
  this.borderColor = '#e0e0e0';
  this.borderStyle = '2px dashed';
}

@HostListener('drop', ['$event']) public onDrop(evt:any){
  evt.preventDefault();
  evt.stopPropagation();
  this.background = '#fff';
  this.borderColor = '#e0e0e0';
  this.borderStyle = '2px dashed';
  // debugger;

  let files = evt.dataTransfer.files;
  this.filesChangeEmiter.emit({target: {files: [files[0]]}});
}
}
