<div class="bg-white">
    <div class="modal-header bg-info2 p-4 pb-2 text-primary">
        <h1 class="modal-title fw-bold">{{'formbuilder.draw-sign' | translate}}</h1>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-3 px-4">
        <div class="row mb-2">
            <div class="signature-pad--body">
                <canvas></canvas>
            </div>
        </div>
        <div class="row">
            {{"formbuilder.sign-above" | translate}}
        </div>
    </div>
    <div class="modal-footer bg-light p-3">
        <button type="button" 
            style="width: 120px;" 
            class="btn btn-outline-secondary me-2 p-3 fw-bold"
            (click)="onCancelClick()">
            {{'others.cancel' | translate}}
        </button>
        <button type="button" 
            style="width: 120px;" 
            class="btn btn-primary p-3 fw-bold" 
            (click)="onConfirmClick()">
            {{'others.confirm' | translate}}
        </button>
    </div>
</div>