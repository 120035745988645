import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { NotificationService, NotificationServiceType } from 'src/app/services/masterpass/notification.service';
import { ErrorHandlerService } from 'src/app/services/utilities/error-handler.service';
import { PushNotificationsService } from 'ng-push-ivy';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserDetail } from 'src/app/interfaces/masterpass/staff';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, OnDestroy {

  notificationListener!: Subscription;
  notificationList: any = [];
  unreadNotificationCount: number = 0;

  take: number = 5;
  page: number = 1;
  order: string = 'DESC';
  read: boolean = false;
  meta: any;

  currentUser!: UserDetail;

  isLoading = false;

  constructor(
    private authService: AuthService,
    private errorHandler: ErrorHandlerService,
    private notificationService: NotificationService,
    private _pushNotifications: PushNotificationsService,
    private router: Router,
    private toastrModule: ToastrService,
  ) {
    this._pushNotifications.requestPermission();
    this.currentUser = localStorage.getItem('auth-user') ? JSON.parse(localStorage.getItem('auth-user')!) : undefined;
  }

  ngOnInit(): void {
    this.getNotifications();
    this.setupNotificationListener();
  }

  ngOnDestroy() {
    this.notificationService.closeListener()
  }

  notify() {
    this._pushNotifications.requestPermission();

    if (!environment.production) {
      let options = { //set options
        body: "Push notifications enabled.",
        icon: "assets/logos/logo_only_transparent.svg"
      }

      this._pushNotifications.create('Onepass ERP Notification', options).subscribe({
        next: res => {
          console.log(res)
        },
        error: e => {
          console.log(e)
        }
      })
    }
  }

  setupNotificationListener() {
    // event source SSE endpoint listener
    this.notificationListener = this.notificationService.listen(NotificationServiceType.NOTIFICATION_LISTEN).subscribe({
      next: (res: any) => {
        // console.log('Message received: ', res);
        if (res.type !== 'error' && res.type !== 'open') {
          const message = JSON.parse(res.event.data)
          // console.log('parsed', message)

          this.toastrModule.info(message.body || '-');
          let options = {
            body: message.body,
            icon: "assets/logos/logo_only_transparent.svg"
          }

          this._pushNotifications.create('Onepass ERP Notification', options).subscribe({
            next: res => {
              switch (message.type) {
                case 'DEPARTMENT_PERMISSION_UPDATED':
                case 'ROLE_PERMISSION_UPDATED':
                case 'PERMISSION_UPDATED':
                  this.authService.getPermissions(this.currentUser)
                  break;
              }
            },
            error: e => {
              console.log(e)
            }
          })

          this.getNotifications();
        }
      },
      error: (e: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(e);
      }
    });
  }

  async getNotifications() {
    this.isLoading = true;
    const pathParams = '?page=' + this.page + '&take=' + this.take + '&order=' + this.order + '&read=' + this.read;
    const req = await this.notificationService.list(NotificationServiceType.NOTIFICATION, pathParams);
    req.subscribe({
      next: (res) => {
        this.notificationList = res.data;
        this.unreadNotificationCount = res.meta.unreadCount || 0;
        this.meta = res.meta;
        this.isLoading = false;
      },
      error: (e: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(e);
        this.isLoading = false;
      }
    })
  }

  async updateNotificationStatus(notifId: string, isRead: boolean) {
    const payload = { isRead: isRead };
    const req = await this.notificationService.update(NotificationServiceType.NOTIFICATION, payload, notifId);
    req.subscribe({
      next: (res) => {
        this.getNotifications();
      },
      error: (e: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(e);
      }
    })
  }

  viewNotification(notification: any) {
    // console.log(notification);
    switch (notification.type) {
      // Human Resource Notification Type
      case 'ATTENDANCE_ASSISTED':
        this.router.navigateByUrl('home/admin/clocking/my-attendance');
        break;
      case 'LEAVE_REQUESTED':
        this.router.navigateByUrl('home/admin/leave/approvals');
        break;
      case 'LEAVE_REQUEST_UPDATE':
        this.router.navigateByUrl('home/admin/leave/my-leaves');
        break;
      case 'CLAIM_REQUESTED':
        this.router.navigateByUrl('home/admin/claims/approvals');
        break;
      case 'CLAIM_REQUEST_UPDATE':
        this.router.navigateByUrl('home/admin/claims/my-claims?claimId=' + notification.metadata.claimId);
        break;
      case 'CLAIM_REQUEST_REPLY':
        this.router.navigateByUrl('home/admin/claims/approvals');
        break;

      // Payroll Notification Type
      case 'PAYSLIP_RECEIVED':
        this.router.navigateByUrl('home/admin/payroll/my-payroll');
        break;
      case 'SOCIAL_SECURITY_REMINDER':
        // Todo: make it possible to set the page to settings page on load
        this.router.navigateByUrl('home/profile');
        break;

      // MasterPass Notification Type
      case 'PROFILE_UPDATED':
        break;
      case 'PASSWORD_UPDATED':
        break;
      case 'PASSWORD_CHANGED_BY_ADMIN':
        break;
      case 'PASSWORD_RESET':
        break;

      // Learning Notification Type
      case 'NEW_COURSE_PUBLISHED':
        this.router.navigateByUrl('home/admin/learning/all-courses');
        break;
      case 'COURSE_REMINDER':
        this.router.navigateByUrl('home/admin/learning/my-learning');
        break;

      // Admin Notification Type
      case 'SYSTEM_INFO':
        break;
      default:
        break;
    }
  }

  async markAllNotificationAsRead() {
    const payload = { isRead: true };
    const req = await this.notificationService.update(NotificationServiceType.NOTIFICATION, payload);
    req.subscribe({
      next: (res) => {
        this.getNotifications();
      },
      error: (e: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(e);
      }
    })
  }

  onPaginationChange(event: any) {
    this.page = parseInt(event.page);
    this.getNotifications();
  }

  onLimitChange(event: any) {
    this.take = parseInt(event.limit);
    this.getNotifications();
  }

  requestPushNotifPermission() {
    this._pushNotifications.requestPermission();
  }
}
