<div class="modal-confirm">
    <div class="modal-header bg-info2 p-4 pb-2 text-primary">
        <h1 class="modal-title fw-bold">{{titleLabel | translate}}</h1>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body p-3 px-4">
        <p class="fs-6 fw-bold text-primary lh-base pt-3 px-3">{{modalLabel}}</p>
    </div>
    <div class="modal-footer bg-light p-3">
        <button type="button" 
            style="width: 120px;" 
            class="btn btn-outline-secondary me-2 p-3 fw-bold"
            (click)="onCancelClick()">
            {{cancelLabel | translate}}
        </button>
        <button type="button" 
            style="width: 120px;" 
            class="btn btn-danger p-3 fw-bold" 
            (click)="onConfirmClick()">
            {{confirmLabel | translate}}
        </button>
    </div>
</div>