import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum UserServiceType {
  USER_LIST,
  USER_PROFILE,
  ORGANISATION_USER_LIST,
  TEAM_MEMBERS,
  EXTERNAL_ORG_ASSIGNMENT,
  CHECK_USED_USERNAME,
  COUNTRY_CODE_PHONE,
  CHECK_VALID_PHONE,
  ACTIVATION_STATUS
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private API_URL: string;
  private API_URL_CHECK: string;
  constructor(
    private httpSvc: HttpClient
  ) {
    this.API_URL = environment.apiUrl + '/user';
    this.API_URL_CHECK = environment.apiUrl + '/check';
  }

  getUrl(serviceType: UserServiceType) {
    let url: string = '';
    switch (serviceType) {
      case UserServiceType.USER_LIST:
        url = this.API_URL + '/';
        break;
      case UserServiceType.USER_PROFILE:
        url = this.API_URL + '/profile';
        break;
      case UserServiceType.ORGANISATION_USER_LIST:
        url = this.API_URL + '/orgMember/';
        break;
      case UserServiceType.TEAM_MEMBERS:
        url = this.API_URL + '/team/members/';
        break;
      case UserServiceType.EXTERNAL_ORG_ASSIGNMENT:
        url = environment.apiUrl + '/external-organisation-assignment';
        break;
      case UserServiceType.CHECK_USED_USERNAME:
        url = this.API_URL_CHECK + '/username/';
        break;
      case UserServiceType.COUNTRY_CODE_PHONE:
        url = this.API_URL_CHECK + '/country-code';
        break;
      case UserServiceType.CHECK_VALID_PHONE:
        url = this.API_URL_CHECK + '/phone-number/';
        break;
      case UserServiceType.ACTIVATION_STATUS:
        url = this.API_URL + '/activation-status/';
        break;
      default:
        break;
    }
    return url;
  }

  async create(serviceType: UserServiceType, body: any): Promise<Observable<any>> {
    return this.httpSvc.post(this.getUrl(serviceType), body);
  }

  async delete(serviceType: UserServiceType, params?: any, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.delete(this.getUrl(serviceType) + path, { body: params });
  }

  async detail(serviceType: UserServiceType, params?: any): Promise<Observable<any>> {
    return this.httpSvc.get(this.getUrl(serviceType) + (params !== undefined ? params : ''));
  }

  async list(serviceType: UserServiceType, params?: any, path: string = ''): Promise<Observable<any>> {
    return this.httpSvc.get<any>(this.getUrl(serviceType) + path, params);
  }

  async update(serviceType: UserServiceType, body: any, path = ''): Promise<Observable<any>> {
    return this.httpSvc.patch(this.getUrl(serviceType) + path, body);
  }

  getCurrentUserDetail() {
    return JSON.parse(localStorage.getItem('auth-user') || '{}');
  }

  getCurrentUserOrganisationDetail() {
    return JSON.parse(localStorage.getItem('current-organisation') || '{}');
  }
}
