import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.css']
})
export class InformationModalComponent implements OnInit {

  @Input() labelHeader = 'Confirm';
  @Input() modalLabel = 'You are not permitted to do this action.';
  @Input() confirmLabel = 'Ok';

  @Output() onConfirmResult = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.onConfirmResult.emit(true);
    this.activeModal.close(true);
  }

  onCancelClick() {
    this.onConfirmResult.emit(false);
    this.activeModal.close(false);
  }
}
