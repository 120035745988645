<div class="container p-4 mt-4">
    <div class="row mb-4">
        <div class="col-8">
            <h3 class="fs-2 fw-semibold text-dark text-uppercase mb-6" style="letter-spacing: 1px;">
                {{'login.contact-us' | translate}}
            </h3>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col-6">
            <div class="contact-point">
                <p class="fs-4 fw-bolder mb-3 pt-3">{{'login.our-head-office' | translate}}</p>
                <p class="fs-5">120 Hillview Avenue, #04-06 S(669594)</p>
            </div>
            <div class="contact-point">
                <p class="fs-4 fw-bolder mb-3 pt-3">{{'login.hours' | translate}}</p>
                <p class="fs-5">{{'login.weekdays' | translate}}: 9 am to 6 pm</p>
            </div>
            <div class="contact-point">
                <p class="fs-4 fw-bolder mb-3 pt-3">{{'login.phone' | translate}}</p>
                <p class="fs-5">+65 9069 5600</p>
            </div>
            <div class="contact-point">
                <p class="fs-4 fw-bolder mb-3 pt-3">{{'login.email' | translate}}</p>
                <p class="fs-5">sales@superink.com.sg</p>
            </div>
            <div>
                <a routerLink="/">
                    <button class="btn btn-dark fs-6 px-4 py-3">
                        {{'login.go-back' | translate}}
                    </button>
                </a>
            </div>
        </div>
        <div class="col-6">
            <mgl-map
                [style]="'mapbox://styles/mapbox/streets-v9'"
                [zoom]="[12]"
                [center]="[103.761592, 1.356954]"
            >
                <mgl-marker [lngLat]="[103.761592, 1.356954]">
                    <div class="marker">
                        <img src="../../../../../assets/icons/marker-icon.png">
                    </div>
                </mgl-marker>
            </mgl-map>
            <a href="https://maps.google.com/maps?ll=1.356954,103.761592&z=17&t=m&hl=en-US&gl=US&mapclient=embed&cid=18234981948735652516" target="_blank">View bigger map</a>
        </div>
    </div>

</div>

<div class="footer text-center mt-2 pt-3">
    <p class="text-muted">&copy; {{'login.all-right-reserved' | translate}}. {{getCurrentYear()}}</p>
</div>
