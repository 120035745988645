import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { SharedModule } from './shared/shared.module';
import { SidebarButtonComponent } from './home/navigation/sidebar-button/sidebar-button.component';
import { NavigationComponent } from './home/navigation/navigation.component';
import { NotificationComponent } from './home/navigation/notification/notification.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SettingComponent } from './home/setting/setting.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from "../_infrastructure/_interceptors/auth.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AdminComponent } from './admin/admin.component';
import { SidebarButtonsComponent } from './shared/sidebar-buttons/sidebar-buttons.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { ModalNavigationButtonComponent } from './home/navigation/modal-navigation-button/modal-navigation-button.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { WebcamModule } from 'ngx-webcam';
import { PushNotificationsModule } from 'ng-push-ivy';
import * as moment from 'moment';
import { MessagingComponent } from './home/messaging/messaging.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatePipe } from './shared/date.pipe';

import localeTa from "@angular/common/locales/ta"
import localeBn from "@angular/common/locales/bn"
import localeId from "@angular/common/locales/id"
import localeCn from "@angular/common/locales/zh-Hans"
import localeMs from "@angular/common/locales/ms"
import localeMy from "@angular/common/locales/my"
import localeEn from "@angular/common/locales/en"
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from 'src/environments/environment';

registerLocaleData(localeEn);
registerLocaleData(localeTa);
registerLocaleData(localeBn);
registerLocaleData(localeCn);
registerLocaleData(localeMs);
registerLocaleData(localeId);
registerLocaleData(localeMy);

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

const env = environment;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SettingComponent,
    AdminComponent,
    SidebarButtonsComponent,
    NavigationComponent,
    SidebarButtonComponent,
    ModalNavigationButtonComponent,
    ResetPasswordComponent,
    NotificationComponent,
    MessagingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    SharedModule,
    MatNativeDateModule,
    MatDialogModule,
    MatDatepickerModule,
    MomentDateModule,
    QRCodeModule,
    WebcamModule,
    ToastrModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    PushNotificationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: env.mapboxToken
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    CurrencyPipe,
    DatePipe,
    NgxDatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}