import { Component, ContentChild, Input, OnInit, TemplateRef, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {

  @ContentChild('tableActionButton', { static: false }) tableActionButton: TemplateRef<any> | undefined;
  @ContentChild('tableFilterOutlet', { static: false }) tableFilterOutlet: TemplateRef<any> | undefined;
  @ContentChild('tableCustomContentOutlet', { static: false }) tableCustomContentOutlet: TemplateRef<any> | undefined;
  @ContentChild('tableHeaderTemplate', { static: false }) tableHeaderTemplate: TemplateRef<any> | undefined;
  @ContentChild('tableCellTemplate', { static: false }) tableCellTemplate: TemplateRef<any> | undefined;
  @ContentChild('cardTemplate', { static: false }) cardTemplate: TemplateRef<any> | undefined;

  @Input() version: number = 1;
  @Input() columnsCount: number = 1;
  @Input() columns: any = [];
  @Input() rows: any = [];
  @Input() searchLabel: string = 'others.search';
  @Input() searchColumn: any = [];
  @Input() limit: number = 10;
  @Input() hideLimit: boolean = false;
  @Input() page: number = 1;
  @Input() meta: any;
  @Input() tokenPage: boolean = false;
  @Input() tokenLength: number = 1;
  @Input() addButton: boolean = false;
  @Input() buttonLabel: string = 'New';
  @Input() isLoading: boolean = false;
  @Output() addButtonClick = new EventEmitter;
  @Output() paginationChange = new EventEmitter<any>(true);
  @Output() limitChange = new EventEmitter<any>(true);

  data: any = [];
  searchInput: any = '';
  tableClass: string = "";
  shortTable = false;
  showTable = true;

  constructor() { }

  ngOnInit(): void {
    this.data = this.rows;
    if (this.limit < 10) this.shortTable = true;

    switch (this.version) {
      case 1:
        this.tableClass = 'table table-borderless table-striped';
        break;
      case 2:
        this.tableClass = 'table-striped2';
        break;
      case 3:
        this.tableClass = 'table';
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.rows) {
      this.data = this.rows;

      if(this.meta) {
        // console.log(true)
      } else {
        if(!this.tokenPage) this.onPaginationChange({page: 1});
        if(!this.tokenPage) this.page = 1;
        // console.log(false)
      }
    }
  }

  onPaginationChange(event: any) {
    if(this.tokenPage) {
      let modifier = 1;
      if(event.type === 'prev') modifier = -1;
      this.paginationChange.emit(modifier);
      this.page = 1;
    }
    else {
      this.page = parseInt(event.page);
      this.paginationChange.emit(this.page)
    }
    
    // this.checkRow();
  }

  onLimitChange(event: any) {
    this.limit = parseInt(event.limit);
    this.limitChange.emit(this.limit)
  }

  doSearch() {
    var searchVal = this.searchInput.trim().toLowerCase();

    this.data = this.rows.filter((record: any) => {

      switch (this.searchColumn.length) {
        case 1:
          try {
            if (record[this.searchColumn[0]].toLowerCase().includes(searchVal)) {
              return record;
            }
          } catch (error) { return '' }
          break;
        case 2:
          try {
            if (
              record[this.searchColumn[0]].toLowerCase().includes(searchVal) ||
              record[this.searchColumn[1]].toLowerCase().includes(searchVal)
            ) {
              return record;
            }
          } catch (error) { return '' }
          break;
        case 3:
          try {
            if (
              record[this.searchColumn[0]].toLowerCase().includes(searchVal) ||
              record[this.searchColumn[1]].toLowerCase().includes(searchVal) ||
              record[this.searchColumn[2]].toLowerCase().includes(searchVal)
            ) {
              return record;
            }
          } catch (error) { return '' }
          break;
        default:
          try {
            if (
              record[this.searchColumn[0]].toLowerCase().includes(searchVal) ||
              record[this.searchColumn[1]].toLowerCase().includes(searchVal) ||
              record[this.searchColumn[2]].toLowerCase().includes(searchVal) ||
              record[this.searchColumn[3]].toLowerCase().includes(searchVal)
            ) {
              return record;
            }
          } catch (error) { return '' }
          break;
      }
    })
  }

  checkRow(){
    const thisPage:any = [];
    this.rows.forEach((element:any, i:number) => {
      if(this.calculateStartRow(i) && this.calculateEndRow(i)){
        thisPage.push(element);
      }
    });

    console.log(thisPage);
    console.log((((this.page-1) * this.limit) + this.limit), (this.page-1) * this.limit);
  }

  calculateStartRow(index:number){
    // console.log(index, (((this.page-1) * this.limit) + this.limit))
    return index < (((this.page-1) * this.limit) + this.limit);
  }

  calculateEndRow(index:number){
    // console.log(index, (this.page-1) * this.limit)
    return index >= (this.page-1) * this.limit;
  }

}
