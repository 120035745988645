import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() { }

  private childSource : any = new BehaviorSubject(null)
  private clocking : any = new BehaviorSubject(null)

  getNavChangesA(): Observable<any> {
    return this.childSource.asObservable();
  }

  setNavChangesA(source: any) {
    this.childSource.next(source);
  }

  getNavChangesB(): Observable<any> {
    return this.childSource.asObservable();
  }

  setNavChangesB(source: any) {
    this.childSource.next(source);
  }

  getPrerequisiteClocking() : Observable<any> {
    return this.clocking.asObservable();
  }

  setPrerequisiteClocking(source:any){
    this.clocking.next(source)
  }
  

}
