<div class="sidebar-options" (click)="optionOnClick()">
    
    <a routerLink="{{optionInfo.routerLink}}" routerLinkActive="active" style="color:black">
        <div [ngClass]="clickedOption == optionInfo.linkText ? 'sidebar-iconsToggle-box' : 'sidebar-icons-box'">
            <img [ngClass]="clickedOption == optionInfo.linkText ? 'sidebar-iconsToggle' : 'sidebar-icons'" src="{{optionInfo.img1}}">
          </div>
        <div class="sidebar-text">
            {{optionInfo.linkText}}    
        </div>
    </a>


</div>