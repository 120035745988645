import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, flatMap, mergeMap, of, forkJoin, catchError, map, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../utilities/error-handler.service';
import { PermissionService } from '../permission/permission.service';
import { ProjectClientService } from '../project/project.client.service';
import { Organisation, Permission } from 'src/app/interfaces/masterpass/policy';
import { UserDetail } from 'src/app/interfaces/masterpass/staff';
import { ToastrService } from 'ngx-toastr';

export const USER_KEY = 'auth-user'
export const USER_ORG_KEY = 'current-organisation'
export const TOKEN_KEY = 'auth-token'
export const PERMISSION_KEY = 'user-permission'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loadingPermission = false;
  private error$ = new Subject<any>();

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private permissionService: PermissionService,
    private toastrService: ToastrService,
    private userProService: ProjectClientService,
  ) { }

  login(username: any, password: any): Observable<any> {
    const payload = { username, password }
    return this.http.post(`${environment.apiUrl}/auth/login`, payload);
  }

  requestOtp(username: any): Observable<any> {
    const payload = { username }
    return this.http.post(`${environment.apiUrl}/auth/forget-password`, payload);
  }

  switchOrganisation(orgId: string): Observable<any> {
    const payload = { organisationId: orgId }
    return this.http.post(`${environment.apiUrl}/auth/switch-organisation`, payload);
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/reset-password`, data);
  }

  // register(username: any, password: any): Observable<any> {
  //   const payload = { username, password }
  //   return this.http.post(`${environment.apiUrl}/user`, payload);
  // }

  /**
   * The `getPermissions` function retrieves and merges permissions for a user based on their
   * organization, department, and role.
   * @param {any} user - The `user` parameter is an object that represents a user. It contains
   * properties such as `id`, `department`, and `role`.
   * @param {string} [type=default] - The `type` parameter is a string that specifies the type of
   * actions after permission retrieve. It has a default value of 'default', but can also be set to 'refresh'.
   */
  public async getPermissions(user: UserDetail, callBackFunction?: Function) {
    const sources = [];

    // use handle request pipe so that if other endpoint fails, the one successfull still processed
    sources.push(await this.permissionService.getUserOrganisationPermission(user.id).pipe(this.handleRequest().bind(this)));
    if (user.department.id) sources.push(await this.permissionService.getDepartmentPermissions(user.department.id).pipe(this.handleRequest().bind(this)))
    if (user.role.id) sources.push(await this.permissionService.getRolePermissions(user.role.id).pipe(this.handleRequest().bind(this)))

    forkJoin(sources).subscribe({
      next: (res: any) => {
        let n = 0;
        let usable: any = [];

        res[n].data.map((permission: any) => {
          if (permission.active && permission.module !== null)
            usable.push(permission);
        })
        const permissions: any = { 0: usable };

        // only run these code if user have department and department permission is fetched successfully
        if (user.department.id && res[n + 1]) {
          n++; usable = [];
          res[n].data.map((permission: any) => {
            if (permission.active && permission.module !== null)
              usable.push(permission);
          })

          // check for similar true permission from previous endpoint, 
          // and leave only one instance of permission per resource type
          let ids = new Set(permissions[n - 1].map((d: Permission) => d.permissionId));
          let merged = [...permissions[n - 1], ...usable.filter((d: Permission) => !ids.has(d.permissionId))];
          permissions[n] = merged;
        }

        // only run these code if user have role and role permission is fetched successfully
        if (user.role.id && res[n + 1]) {
          n++; usable = [];
          res[n].data.map((permission: any) => {
            if (permission.active && permission.module !== null)
              usable.push(permission);
          })

          // check for similar true permission from previous endpoint, 
          // and leave only one instance of permission per resource type
          let ids = new Set(permissions[n - 1].map((d: Permission) => d.permissionId));
          let merged = [...permissions[n - 1], ...usable.filter((d: Permission) => !ids.has(d.permissionId))];
          permissions[n] = merged
        }

        // save permissions to local storage
        this.permissionService.saveUserPermission(permissions[n]);

        // run callback function if there is any
        if (callBackFunction) callBackFunction();
      },
      error: (e: HttpErrorResponse) => {
        this.permissionService.saveUserPermission([]);
        this.errorHandler.handleHttpError(e);
        if (callBackFunction) callBackFunction();
      }
    })
  }

  /**
   * The function `switchOrganisationToken` switches the user's current organisation and updates the
   * user's token, permissions, and organisation information.
   * @param {Organisation} organisation - The `organisation` parameter is an object of type
   * `Organisation`.
   */
  public switchOrganisationToken(organisation: Organisation) {
    const newOrganisation = organisation;
    const switchReq = this.switchOrganisation(newOrganisation.id)
    switchReq.subscribe({
      next: async (res: any) => {
        await this.saveToken(res.data.accessToken);

        await this.userProService.clearCurrentuserOrganisation();
        await this.userProService.setCurrentuserOrganisation(newOrganisation);

        await this.permissionService.clearUserPermission();
        await this.getPermissions(this.getUser());

        setTimeout(() => {
          window.location.reload();
        }, 300);
      },
      error: (e: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(e);
      }
    });
  }

  /* The `saveUser` method is used to save the user object to the browser's local storage. It first
  checks if the `user` parameter is defined and not equal to the string `'undefined'`. If it is, it
  removes any existing user data from the local storage and then sets the `USER_KEY` with the
  serialized `user` object. */
  public saveUser(user: any) {
    if (!user || user == 'undefined') return;
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  /* The `saveUserOrganisation` method is used to save the user organisation object to the browser's local storage. It first
  checks if the `organisation` parameter is defined and not equal to the string `'undefined'`. If it is, it
  removes any existing user data from the local storage and then sets the `USER_ORG_KEY` with the
  serialized `organisation` object. */
  public saveUserOrganisation(organisation: any) {
    if (!organisation || organisation == 'undefined') return;
    window.localStorage.removeItem(USER_ORG_KEY);
    window.localStorage.setItem(USER_ORG_KEY, JSON.stringify(organisation));
  }

  /**
   * The function retrieves a user object from local storage and returns it, or an empty object if no
   * user is found.
   * @returns an object.
   */
  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) { return JSON.parse(user); }
    return {};
  }

  /**
   * The function retrieves a organisation object from local storage and returns it, or an empty object if no
   * organisation is found.
   * @returns an object.
   */
  public getUserOrganisation(): any {
    const organisation = window.localStorage.getItem(USER_ORG_KEY);
    if (organisation) { return JSON.parse(organisation); }
    return {};
  }

  /**
   * The function saves a token to the local storage if it is not undefined.
   * @param {any} token - The `token` parameter is of type `any`, which means it can accept any data
   * type.
   * @returns If the token is undefined or falsy, nothing is being returned.
   */
  public saveToken(token: any) {
    if (!token || token == 'undefined') return;
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  }

  /**
   * The function retrieves a token from the browser's local storage and returns it as an object.
   * @returns the token value stored in the local storage. If the token exists, it is parsed from JSON
   * format and returned. If the token does not exist, an empty object is returned.
   */
  public getToken(): any {
    const token = window.localStorage.getItem(TOKEN_KEY);
    if (token) {
      return JSON.parse(token);
    }
    return {};
  }

  /**
 * The function retrieves a token from the browser's local storage and returns it as an object.
 * @returns the token value stored in the local storage. If the token exists, it is parsed from JSON
 * format and returned. If the token does not exist, an empty object is returned.
 */
  public getStoredUserPermissions(): any {
    const permissions = window.localStorage.getItem(PERMISSION_KEY);
    if (permissions) {
      return permissions;
    }
    return {};
  }

  /**
   * The function removes specific items from the local storage and returns true.
   * @returns true
   */
  logout() {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(PERMISSION_KEY);
    window.localStorage.removeItem('current-project')
    window.localStorage.removeItem('current-organisation')
    return true;
  }

  /**
   * The function handles an observable by mapping the result and catching any errors.
   * @returns The `handleRequest()` function returns a function that takes an `observable` as a
   * parameter. This returned function then applies a series of operators to the `observable` using the
   * `pipe()` method. The operators include `map()` and `catchError()`. Finally, the modified
   * `observable` is returned.
   */
  handleRequest() {
    return (observable: Observable<any>) => {
      return observable.pipe(
        map((result) => {
          // console.log('Each User Data Object', result);
          return result;
        }),
        catchError((err) => {
          this.error$.next(err);
          return of(null);
        })
      );
    };
  }
}
