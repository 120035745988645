<div *ngIf="version===1" class="summary-component px-4 py-2 border-0"
   [ngClass]="{'bg-success3 text-white': style=== 'success',
      'bg-warning3 text-white': style === 'warning',
      'bg-danger3 text-white': style === 'danger',
      'bg-brand3 text-white': style === 'brand',
      'bg-white text-dark': style === 'white'}">
   <div class="d-flex flex-row justify-content-center align-items-center">
      <div *ngIf="icon" class="rounded-pill p-2-5" 
         [ngStyle]="{'background-color': style === 'success' ? '#C3FFDB' : 
            style === 'warning' ? '#FFEBC3' : 
            style === 'danger' ? '#FFC3C3' : 
            style === 'white' ? '#E9F3FF' : '#EEE2F8'}">
         <div *ngIf="!icon.includes('png')">
            <i [class]="'bi ' + icon + ' fs-1'"
               [ngClass]="{'text-success': style=== 'success',
               'text-warning': style === 'warning',
               'text-danger': style === 'danger',
               'text-brand': style === 'brand',
               'text-grey': style === 'white'}">
            </i>
         </div>
         <div *ngIf="icon.includes('png') || icon.includes('jpg') || icon.includes('svg')" 
            class="text-end p-0">
            <img [src]="icon" alt="" class="summary-icon">
         </div>
      </div>
      <div class="d-flex flex-column pt-2 ms-3">
         <h4 class="mb-1 fw-bold"> 
            {{title}} 
         </h4>
         <p class="fw-bold">
            <span class="big-digits"
               [ngClass]="{'fs-6': valueDisplay.length > 8, 'fs-4': valueDisplay.length < 8}">
               {{value}}
            </span>
            {{unitOfMeasurement}}
            <span 
               *ngIf="showReadMore" 
               class="fw-normal text-white fs-8 btn btn-link p-0 ms-0" 
               (click)="onReadMoreClick()">
               ({{'others.view-detail' | translate}})
            </span>
         </p>
      </div>
   </div>
</div>

<div *ngIf="version===2" class="summary-component-2 px-4 py-2 mb-4">
   <div class="row d-flex justify-content-start align-items-center">
      <div *ngIf="icon" class="col-3">
         <div *ngIf="!icon.includes('png')">
            <i [class]="'bi ' + icon + ' fs-1'"></i>
         </div>
         <div *ngIf="icon.includes('png') || icon.includes('jpg')" 
            class="text-end p-0">
            <img [src]="icon" alt=""
               width="40px" height="40px" class="summary-icon"
               style="background-color: transparent;">
         </div>
      </div>
      <div [ngClass]="{'col-9': icon, 'col-12': !icon}">
         <div class="col d-flex justify-content-between">
            <h3 [ngClass]="{'fs-7': title.length > 21}"> {{title}} </h3>
            <span 
               *ngIf="showReadMore" 
               class="fw-normal fs-8 btn btn-link p-0" 
               (click)="onReadMoreClick()">
               View More
            </span>
         </div>
         <p class="fw-normal">
            <span class="big-digits fw-bold fs-4"> 
               {{value}}
            </span>
            <span class="">
               {{unitOfMeasurement}}
            </span>
         </p>
      </div>
   </div>
</div>


<div *ngIf="version===3" class="px-1 py-2 mb-4">
   <div class="row">
      <div [class]="'col rounded inset-round-' + style + '-e d-flex justify-content-center align-items-center'"
         style="height: 90px;">
         <div *ngIf="!icon.includes('png')">
            <i [class]="'bi ' + icon + ' fs-1'"></i>
         </div>
         <div *ngIf="icon.includes('png') || icon.includes('jpg')" 
            class="text-end p-0">
            <img [src]="icon" alt=""
               class="summary-icon me-1"
               style="background-color: transparent;height:40px">
         </div>
         <div class="d-flex flex-column align-items-start">
            <h3 class="mb-0 ms-2 fw-bold" 
               [ngClass]="{'fs-7': title.length > 21}"> 
               {{title}}
            </h3>
            <span 
               *ngIf="showReadMore" 
               class="fw-normal text-white fs-8 btn btn-link p-0 ms-2" 
               (click)="onReadMoreClick()">
               View Details
            </span>
         </div>
      </div>
      <div style="min-width: 80px; max-width: 100px;" class="col rounded inset-round-s d-flex align-items-center justify-content-center border-dash-start">
         <p class="fw-normal pt-2" [ngClass]="{'text-center': unitOfMeasurement}">
            <span class="big-digits fw-bold" [ngClass]="{'fs-6': valueDisplay.length > 8, 'fs-4': valueDisplay.length < 8}"> 
               {{value}}
            </span> <br>
            <span class="">
               {{unitOfMeasurement}}
            </span>
         </p>
      </div>
   </div>
</div>