import { PermissionService } from 'src/app/services/masterpass/permission/permission.service';
import { UtilityService } from 'src/app/services/utilities/utility.service';
import { NavbarEntries } from './../../navigation-entries';
import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/masterpass/organisation/organisation.service';
import { ProjectClientService } from 'src/app/services/project/project.client.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/services/utilities/error-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { MasterpassService, MasterpassServiceType } from 'src/app/services/masterpass/masterpass.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { fullscreenRoutesProject } from '../module-project/project.component';
import * as Sentry from "@sentry/angular";

const ProjectsRouting = {
  img1: "bi bi-kanban fs-4",
  img2: "bi bi-kanban fs-4",
  resource: true,
  routerLink: "",
  linkText: "Project Management",
  children: [
      // {
      //     img1: "bi bi-chevron-left fs-4",
      //     img2: "bi bi-chevron-left fs-4",
      //     routerLink: "#back",
      //     linkText: "Project Management ",
      //     children: [],
      //     resource: true,
      //     style: 'heading'
      // },
      {
          img1: "bi bi-grid fs-4",
          img2: "bi bi-grid fs-4",
          routerLink: "/home/project/overview",
          linkText: "Overview",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-layout-text-window-reverse fs-4",
          img2: "bi bi-layout-text-window-reverse fs-4",
          routerLink: "/home/project/document-management",
          linkText: "Document Management",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-folder fs-4",
          img2: "bi bi-folder fs-4",
          routerLink: "/home/project/file-manager",
          linkText: "Master File Manager",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-chevron-left fs-4",
          img2: "bi bi-chevron-left fs-4",
          routerLink: "#divider",
          linkText: "Project 1 ",
          children: [],
          resource: true,
      },
      {
          img1: "bi bi-file-ruled fs-4",
          img2: "bi bi-file-ruled fs-4",
          routerLink: "/home/project/forms",
          linkText: "Forms",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-columns-gap fs-4",
          img2: "bi bi-columns-gap fs-4",
          routerLink: "/home/project/project-overview",
          linkText: "Project Overview",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-calendar2-range fs-4",
          img2: "bi bi-calendar2-range fs-4",
          routerLink: "/home/project/timeline",
          linkText: "Timeline",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-list-task fs-4",
          img2: "bi bi-list-task fs-4",
          routerLink: "/home/project/task-assignment",
          linkText: "Task Assignment",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-person-lines-fill fs-4",
          img2: "bi bi-person-lines-fill fs-4",
          routerLink: "/home/project/employee-tracking",
          linkText: "Employee Tracking",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-file-earmark-check fs-4",
          img2: "bi bi-file-earmark-check fs-4",
          routerLink: "/home/project/daily-task-report",
          linkText: "Daily Task Report",
          resource: true,
          children: []
      },
      {
          img1: "bi bi-file-earmark-text fs-4",
          img2: "bi bi-file-earmark-text fs-4",
          routerLink: "/home/project/file-manager",
          linkText: "File Management",
          resource: true,
          children: []
      }
  ]
}

@Component({
  standalone: false,
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnInit {

  clickedOption: any // Used to check active or not
  organisationsList: any = [];
  notificationList: any = [];
  unreadNotificationCount: number = 0;

  isMinimized: boolean = false
  userData: any

  selectedLanguage = "en";
  selectedLanguageName = "English";
  currentUser: any;
  currentOrganisation: any;

  visibleSystemOption: any[] = []; // All menu items
  visibleSystemOptionChildren: any = []; // Submenus for the corresponding parent item
  visibleParent:any = []; // To tell which parent menu should be opened
  devRoutes:any = ProjectsRouting;

  systemOptions = NavbarEntries;
  orgLoading = false;

  languageCodes: string[] = [];
  languagesList: any = [];

  navigateLimiter: boolean = false;

  fullscreenRoutes: string[] = fullscreenRoutesProject;
  isHideTopbar: boolean = false;

  constructor(
    private authService: AuthService,
    private errorHandler: ErrorHandlerService,
    private organisationService: OrganisationService,
    private masterpassService: MasterpassService,
    private permissionService: PermissionService,
    private router: Router,
    private translate: TranslateService,
    private toastrService: ToastrService,
    public userProService: ProjectClientService,
    public utilityService: UtilityService,
  ) {
    this.currentUser = localStorage.getItem('auth-user') ? JSON.parse(localStorage.getItem('auth-user')!) : {};
    this.selectedLanguage = this.currentUser?.language?.code || "en";
    this.selectedLanguageName = this.currentUser?.language?.nativeName || 'English';
    translate.use(this.selectedLanguage);
    translate.use(this.selectedLanguage);
  }

  async ngOnInit() {
    this.visibleSystemOption = this.systemOptions;
    if(!environment.production) {
      this.visibleSystemOption = [...this.visibleSystemOption, this.devRoutes]
    }

    const userData = localStorage.getItem('auth-user');
    if (userData) { this.userData = JSON.parse(userData) }

    this.getOrganisationList();

    // since navigation component always loaded from anywhere,
    // so when app is reloaded, this code here will fetch current user organisation
    const permission = this.permissionService.getUserPermission()
    setTimeout(() => {
      if (permission.length == 0) this.authService.getPermissions(this.userData);
    }, 300);

    setTimeout(() => { if (window.innerWidth < 720) this.setMobileView() }, 150);

    this.router.events.subscribe((event:any) => {
      if(event.type === 1){
        if(this.fullscreenRoutes.some(v => event.url.includes(v))){
          this.isHideTopbar = true;
        } else if (!this.fullscreenRoutes.some(v => event.url.includes(v))) {
          this.isHideTopbar = false;
        }
      }
    });
  }

  // get all organisation current user is assigned to.
  async getOrganisationList() {
    const req = await this.organisationService.getSelfOrganisation();
    req.subscribe({
      next: (res) => {
        // this.organisationsList.push(this.currentOrganisation)
        res.data.forEach((element: any) => {
          if (element.name !== null)
            this.organisationsList.push(element)
        });

        // console.log(this.organisationsList)
        this.orgLoading = false;
      },
      error: (e: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(e);
        this.orgLoading = false;
      }
    })
  }

  onClickEvent(event: any, mobile: boolean = false) {
    if(!this.navigateLimiter){
      this.navigateLimiter = true;
      setTimeout(() => { this.navigateLimiter = false }, 300);

      this.clickedOption = event.linkText
      if (event.routerLink !== "" && event.routerLink !== "#back" && event.routerLink !== "#divider")
        this.router.navigateByUrl(event.routerLink)

      // If menu has children
      if (event.children.length > 0) {
        // If parent menu not clicked, add to these 2 var to show it
        // Else, if sidebar not minimized, remove parent menu from those 2 var
        if(!this.visibleParent.includes(event.linkText)){
          this.visibleParent.push(event.linkText);
          this.visibleSystemOptionChildren.push(event.children);
        } else {
          if(!this.isMinimized){
            const findIndex = this.visibleParent.findIndex((x:string) => x === event.linkText);
            if(findIndex !== -1) {
              this.visibleParent.splice(findIndex, 1);
              this.visibleSystemOptionChildren.splice(findIndex, 1);
            }
          }
        }
      } else {
        if (mobile) (this.closeModal())
      }
    }
  }

  findParentIndex(parent:string){
    const findIndex = this.visibleParent.findIndex((x:string) => x === parent);
    if(findIndex !== -1) {
      return findIndex
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let currentWidth = event.target.innerWidth;
    console.log(currentWidth)
    if (currentWidth < 720) {
      this.setMobileView()
    }
  }

  setMobileView() {
    let sidebar = Array.from(document.getElementsByClassName('sidebar') as HTMLCollectionOf<HTMLElement>)
    let toggleMenu = document.getElementById("toggle-mobile-menu")
    let navbar = Array.from(document.getElementsByClassName('navbar') as HTMLCollectionOf<HTMLElement>)
    let mainContent = document.getElementById('homeContentWrapper')

    if (toggleMenu) {
      toggleMenu.setAttribute("style", "display: block !important");
      sidebar[0].setAttribute("style", "display: none !important");
      for (let k = 0; k < navbar.length; k++) {
        navbar[k].setAttribute("style", "display: none !important")
      }
      if (mainContent) {
        mainContent.setAttribute("style", "margin-top: 60px; left: 0px !important;")
        mainContent.setAttribute("class", "router-content")
      }
    }
  }

  openModal() {
    let modal = document.getElementById("sidebar-modal");

    if (modal) {
      modal.style.display = "block";
    }
    // if user clicks anywhere else except for modal contents
    window.onclick = function (event) {
      if (event.target == modal && modal) {
        modal.style.display = "none";
      }
    }
  }

  closeModal() {
    let modal = document.getElementById("sidebar-modal");
    if (modal) {
      modal.style.display = "none";
    }
  }

  onOrgChange(event: any) {
    this.authService.switchOrganisationToken(JSON.parse(event.target.value));
  }

  onNavigateClick(route: string, home:boolean = true) {
    if(home) this.router.navigate(['home', route]);
    else this.router.navigate([route]);
  }

  onReportErrorClick(){
    const eventID = Sentry.captureMessage('New OnepassERP Feedback');
    Sentry.showReportDialog({ eventID });


    // Can use customized modal UI with this code to submit the feedback

    // const eventId = Sentry.captureException(
    //   new Error("Something went wrong!"),
    // );
    // OR: const eventId = Sentry.lastEventId();
    
    // All feedback fields are optional, except `message`.
    // const userFeedback = {
    //   name: "John Doe",
    //   email: "john@doe.com",
    //   message: "I really like your App, thanks!",
    //   associatedEventId: eventId,
    // };
    // Sentry.captureFeedback(userFeedback);
  }

  onLogoutClick() {
    if (this.authService.logout()) this.router.navigate(['']);
  }
}
