<div class="bg-white rounded">
    <div class="modal-header bg-info2 p-4 pb-2 text-primary">
        <h1 class="modal-title fw-bold">{{title}}</h1>
    </div>
    
    <div class="modal-body">
        <div class="row">
            <div class="col-6 mb-3">
                <mgl-map
                    [style]="'mapbox://styles/mapbox/streets-v9'"
                    [zoom]="[zoom]"
                    [center]="mapCenter"
                    (mapLoad)="onMapReady($event)"
                >
                    <mgl-marker *ngFor="let item of markers" 
                        [lngLat]="[item.lng, item.lat]">
                        <div class="marker"> <img [src]="item.img"></div>
                    </mgl-marker>
                </mgl-map>

                <div class="position-absolute" style="bottom: 40px; left:20px; z-index: 500;">
                    <div class="badge bg-success2 rounded-pill text-dark fs-7 fw-normal p-2 me-3">
                        {{'clocking.latitude' | translate}}:
                        {{position.lat || 0}}
                    </div>
                    <div class="badge bg-success2 rounded-pill text-dark fs-7 fw-normal p-2">
                        {{'clocking.longitude' | translate}}:
                        {{position.lng || 0}}
                    </div>
                </div>
            </div>

            <div class="col-6 mb-3">
                <p class="mb-1 mt-0">{{'clocking.site-name' | translate}}</p>
                <p class="fw-bold fs-6">{{siteName}}</p>

                <span *ngIf="type === 'start'" class="badge rounded-pill bg-success3 text-dark fs-6 my-3 py-1 px-2 mb-1">
                    {{'clocking.check-in' | translate | uppercase}}
                </span>
                <span *ngIf="type === 'end'" class="badge rounded-pill bg-warning py-1 px-2 fs-6 mb-1">
                    {{'clocking.check-out' | translate | uppercase}}
                </span>
                <p class="fw-bold fs-6">{{time | date: 'dd MMMM yyyy hh:mm a'}}</p>
            </div>
        </div>
    </div>
    
    <div class="modal-footer bg-light p-3">
        <button type="button" 
            class="btn btn-primary p-3 fw-bold" 
            (click)="onCancel()">
            {{ cancelBtnLabel || 'Close' }}
        </button>
    </div>
</div>