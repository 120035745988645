<div class="container-fluid">
  <div class="row" style="height: 100vh;">
    <div class="col-xs-2 col-sm-2 col-md-5 col-xl-6 p-0 m-0">
      <img class="mb-0 img-fluid h-100" style="object-fit: cover;" src="/assets/login-bg.jpg" alt="">
    </div>
    <div class="col-xs-10 col-sm-10 col-md-7 col-xl-6 form-container">
      <form class="mb-4 d-flex flex-column align-items-center justify-content-center h-100">
        <div class="d-flex flex-column w-100">
          <img class="mb-2 w-75 img-fluid" style="max-width: 300px;" src="/assets/logos/logo_landscape-only.svg" alt="">
          <h1 class="ps-0 mb-2 fw-bold fs-3">{{'login.welcome-back' | translate}}</h1>
          <p class="ps-0 mb-5 fw-bold text-muted">{{'login.sign-in-continue' | translate}}</p>

          <ng-container *ngIf="loginError">
            <div class="alert alert-danger2 w-100 mb-0 d-flex" role="alert">
              <i class="bi-exclamation-triangle pe-2 fs-5"></i>
              {{'login.incorrect-login' | translate}}
            </div>
          </ng-container>

          <div class="form-group row ms-0 py-2 w-100">
            <label class="fw-bold py-2 ps-0">{{'login.username' | translate}} <span class="text-danger fw-normal">*</span></label>
            <div class="col-sm-12 px-0">
                <div class="input-group" [ngClass]="{'border border-2 border-danger rounded': loginError}">
                  <input 
                    type="text" 
                    class="form-control" 
                    [placeholder]="'login.enter-your-username' | translate" #email>
                </div>
            </div>
          </div>

          <div class="form-group row ms-0 py-2 w-100">
            <label class="fw-bold py-2 ps-0">{{'login.password' | translate}} <span class="text-danger fw-normal">*</span></label>
            <div class="col-sm-12 px-0">
              <div class="input-group" [ngClass]="{'border border-2 border-danger rounded': loginError}">
                <input 
                  [type]="passwordVisible ? 'text' : 'password'" 
                  class="form-control rounded" 
                  [placeholder]="'login.enter-your-password' | translate" #password>
                <span class="input-group-text border-0 p-2 password-visible-toggle" (click)="passwordVisible = !passwordVisible">
                  <i class="fs-4 text-primary" [ngClass]="{'bi-eye-fill': passwordVisible, 'bi-eye-slash': !passwordVisible}"></i>
                </span>
              </div>
            </div>
          </div>

          <div class="row px-2">
            <div class="checkbox mb-4">
              <label>
                <input type="checkbox" value="remember-me" [ngModelOptions]="{standalone: true}" [(ngModel)]="rememberMe"> {{'login.remember-me' | translate}}
              </label>
            </div>
          </div>

          <button class="mb-3 btn btn-primary" type="submit" (click)="signIn(email.value, password.value)">
            <div class="d-flex flex-row justify-content-center">
              <span class="fw-bold fs-7">{{'login.login' | translate}}</span> 
              <div *ngIf="isLoading" class="animate-spin">
                <i class="bi bi-arrow-repeat"></i>
              </div>
            </div>
          </button>
          
          <button class="mb-3 btn btn-default border border-2" type="submit" (click)="onNavigateClick('reset-password', false)">
            <div class="d-flex flex-row justify-content-center">
              <span class="fw-bold fs-7">{{'login.forgot-password?' | translate}}</span> 
            </div>
          </button>

          <!-- <div class="footer text-center p-3">
            <div class="row">
              <p class="mb-3 text-muted">&copy; {{'login.all-right-reserved' | translate}}. 2017 – {{getCurrentYear()}}</p>
            </div>
            <div class="row">
              <div class="col">
                <button (click)="onNavigateClick('privacy-policy', false)"
                    class="fs-7 mx-2 border-0 bg-white p-1">
                    <i class="bi bi-shield-check me-2"></i>
                    {{'login.privacy-policy' | translate}}
                  </button>
                  <button (click)="onNavigateClick('terms-conditions', false)"
                    class="fs-7 mx-2 border-0 bg-white p-1">
                    <i class="bi bi-layout-text-sidebar-reverse me-2"></i> {{'login.terms-condition' | translate}}
                  </button>
                  <button (click)="onNavigateClick('contact-us', false)"
                    class="fs-7 mx-2 border-0 bg-white p-1">
                    <i class="bi bi-telephone-plus me-2"></i> {{'login.contact-us' | translate}}
                  </button>
                <div class="input-group mx-auto">
                  <select 
                      (change)="onLanguageSelectChange($event)"
                      class="form-control bg-light rounded-end rounded-pill border-end-0 w-25">
                      <option value="en">English</option>
                      <option value="ta">Tamil</option>
                      <option value="bn">Bengali</option>
                  </select>
                  <span class="input-group-text bg-light rounded-start rounded-pill px-2">
                      <i class="bi bi-chevron-down"></i>
                  </span>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </form>
    </div>
  </div>
</div>
