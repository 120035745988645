<div>

    <ng-container *ngIf="version === 3">
        <div class="row">
            <ng-container *ngIf="searchColumn.length === 0">
                <ng-container
                    *ngIf="tableFilterOutlet"
                    [ngTemplateOutlet]="tableFilterOutlet">
                </ng-container>
            </ng-container>
            <ng-container>
                <div class="col mb-2" *ngIf="!tableFilterOutlet || searchColumn.length !== 0">
                    <label class="floating-label" *ngIf="searchColumn.length > 0">
                        {{'others.search' | translate}}
                    </label>
                    <input *ngIf="searchColumn.length > 0"
                        type="text"
                        style="min-width: 140px;"
                        [ngClass]="'w-100 form-control bg-light rounded p-3'"
                        [placeholder]="searchLabel | translate"
                        [(ngModel)]="searchInput"
                        (keyup)="doSearch()">
                </div>
            </ng-container>
            <div class="row col-lg-auto col-md-12 col-xs-12">
                <div class="col-lg-auto col-md-12 col-xs-12 my-auto">
                    <app-table-pagination
                        [version]="version"
                        [shortTable]="shortTable"
                        [addButton]="false"
                        [limit]="limit"
                        [hideLimit]="hideLimit"
                        [meta]="meta"
                        [page]="page"
                        [style]="'-'"
                        [tokenPage]="tokenPage"
                        [tokenLength]="tokenLength"
                        (paginationChange)="onPaginationChange($event)"
                        (limitChange)="onLimitChange($event)"
                        [rows]="rows">
    
                         <!-- customized table action / add button -->
                        <ng-template #tableActionButtonPassBy>
                            <ng-container
                                *ngIf="tableActionButton"
                                [ngTemplateOutlet]="tableActionButton">
                            </ng-container>
                        </ng-template>
    
                    </app-table-pagination>
                </div>
                <div class="col-lg-auto col-md-12 col-xs-12" *ngIf="addButton">
                    <button
                        style="min-width: 120px;"
                        class="btn btn-primary btn-block btn-add mt-3 p-3 px-3 fw-bold" 
                        (click)="addButtonClick.emit()">
                        {{buttonLabel === 'New' ? ' + ' + buttonLabel : buttonLabel}}
                    </button>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="tableCustomContentOutlet">
            <ng-container
                [ngTemplateOutlet]="tableCustomContentOutlet">
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="version === 1 || version === 2">
        <div class="d-flex flex-row justify-content-between ms-2">
            <div [style]="version === 2 ? 'min-width:250px;' : 'min-width: 200px;'">
                <div class="btn-group" *ngIf="cardTemplate">
                    <button (click)="showTable = true" class="btn btn-outline-primary" [ngClass]="{'active': showTable}"><i class="bi-table"></i></button>
                    <button (click)="showTable = false" class="btn btn-outline-primary" [ngClass]="{'active': !showTable}"><i class="bi-grid"></i></button>
                </div>
                <div class="search-input-group mb-3">
                    <input
                        *ngIf="searchColumn.length > 0" 
                        type="text"
                        [ngClass]="version === 2 ? 'w-100 rounded-pill px-3 py-2 bg-light border-0' : 
                            'w-100 search-bar rounded-pill px-3 py-1'"
                        placeholder="Search"
                        [(ngModel)]="searchInput"
                        (keyup)="doSearch()">
                    <img *ngIf="searchColumn.length > 0" 
                        src="../assets/icons/icons8-search-48.png" 
                        class="position-absolute search-icon "
                        [ngClass]="version === 2 ? 'mt-2 me-4' : ''">
                </div>
            </div>
    
            <app-table-pagination *ngIf="!isLoading"
                [shortTable]="shortTable"
                [addButton]="addButton"
                [buttonLabel]="buttonLabel"
                [limit]="limit"
                [meta]="meta"
                (addButtonClick)="addButtonClick.emit()"
                (paginationChange)="onPaginationChange($event)"
                (limitChange)="onLimitChange($event)"
                [rows]="rows">
    
                <!-- customized table action / add button -->
                <ng-template #tableActionButtonPassBy>
                    <ng-container
                        *ngIf="tableActionButton"
                        [ngTemplateOutlet]="tableActionButton">
                    </ng-container>
                </ng-template>
    
            </app-table-pagination>
        </div>
    </ng-container>

    <ng-container *ngIf="searchColumn.length > 0">
        <ng-container
            *ngIf="tableFilterOutlet"
            [ngTemplateOutlet]="tableFilterOutlet">
        </ng-container>
    </ng-container>

    <ng-container *ngIf="showTable">
        <div class="row mt-4 overflow-mobile-auto">
            <table 
                [class]="tableClass" 
                id="overview-table">
                <thead>
                    <ng-container
                        *ngIf="tableHeaderTemplate"
                        [ngTemplateOutlet]="tableHeaderTemplate">
                    </ng-container>
                </thead>

                <tbody>

                    <ng-container *ngIf="!isLoading">
                        <ng-container *ngIf="rows?.length === 0 || rows === undefined">
                            <tr>
                                <td [colSpan]="columns?.length > 0 ? columns?.length + 1 : columnsCount">
                                    <div class="text-center p-4">
                                        <img class="img-fluid" 
                                            src="assets/illustrations/table-no_data.png">
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="rows?.length > 0">
                            <ng-container *ngFor="let rowDetail of data; index as i">
                                <ng-container *ngIf="meta">
                                    <ng-container *ngIf="i < limit">
                                        <ng-container
                                            *ngIf="tableCellTemplate"
                                            [ngTemplateOutlet]="tableCellTemplate"
                                            [ngTemplateOutletContext]="{$implicit:rowDetail, index: i+1}"
                                        >
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!meta">
                                    <ng-container *ngIf="calculateStartRow(i) && calculateEndRow(i)">
                                        <ng-container
                                            *ngIf="tableCellTemplate"
                                            [ngTemplateOutlet]="tableCellTemplate"
                                            [ngTemplateOutletContext]="{$implicit:rowDetail, index: i+1}"
                                        >
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="isLoading">
                        <tr>
                            <td [colSpan]="columns?.length > 0 ? columns?.length + 1 : columnsCount">
                                <div class="text-center p-4">
                                    <div class="animate-spin">
                                        <i class="bi bi-arrow-repeat fs-1"></i><br>
                                    </div>
                                    <span>Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
        </div>
    </ng-container>

    <!-- <ng-container *ngIf="!showTable">
        <div class="row mb-3">
            <div class="card-grid">
                <ng-container *ngIf="rows?.length > 0">
                    <ng-container *ngFor="let rowDetail of data; index as i">
                        <ng-container *ngIf="i <((page-1)*limit + limit) && i>= (page-1)*limit">
                            <ng-container
                                *ngIf="cardTemplate"
                                [ngTemplateOutlet]="cardTemplate"
                                [ngTemplateOutletContext]="{$implicit:rowDetail}">
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container> -->

    <ng-container *ngIf="version === 1 || version === 2">
        <div class="d-flex flex-row justify-content-end" *ngIf="!isLoading">
            <div class="text-muted fs-7" *ngIf="rows"> <!-- YZ's Hotfix for when table renders before data is loaded -->
                Showing: {{(page-1) * limit + 1}} 
                - {{ ((page-1) * limit + limit) > rows.length ? 
                       meta ? (page-1) * limit + rows.length 
                           : rows.length 
                           : (page-1) * limit + limit }} 
                of {{meta ? meta.itemCount : rows.length}}
            </div>
        </div>
    </ng-container>

</div>
